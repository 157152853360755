<template>
  <el-dialog append-to-body title="选择客户信息"   :show-close="true" :visible.sync="dialogVisible" width="70%">
    <div class="header-select">  
      <el-form :model="filter" ref="detailModelForm"  :inline="true">
            <el-form-item label="客户名称">
                  <el-input v-model="filter.name" placeholder="请输入客户名称" @keyup.enter.native="handleQuery()" />
            </el-form-item>
            <el-form-item style="margin-left: 32px;">
              <el-button type="default" @click="handleReset">重置</el-button>
              <el-button type="primary" @click="handleQuery()">查询</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div>
      <div class="p-table">
        <el-table ref="customerInfoTable" v-loading="tableLoading" :data="customers":header-cell-style="handleHeaderCellStyle"  border
                  stripe @selection-change="handleSelection" @current-change="handleCurrentChange">
          <el-table-column fixed="left" type="selection" width="50"/>
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column  label="客户名称" min-width="140" prop="name"
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="税号" min-width="160" prop="taxNo"
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="地址电话" min-width="140"
                           prop="addressTel" show-overflow-tooltip></el-table-column>
          <el-table-column  label="开户行及账号" min-width="140"
                           prop="bankAccount" show-overflow-tooltip></el-table-column>
          <el-table-column  label="邮箱地址" min-width="140" prop="email"
                           show-overflow-tooltip></el-table-column>
          <el-table-column  label="联系人" min-width="80" prop="contact"
                           show-overflow-tooltip></el-table-column>
          <el-table-column  label="联系人电话" prop="contactTel" show-overflow-tooltip
                           width="120"></el-table-column>
          <el-table-column label="组织名称" prop="orgName" show-overflow-tooltip
                           width="160"></el-table-column>
          <!-- <el-table-column v-if="colData[8] && colData[8].isTrue" fixed="right"  label="操作" prop="operate"
                           width="110">
            <template slot-scope="scope">
              <div class="operate-button">
                <el-button size="medium" style="padding: 0" type="text" @click="handleShowDialog('update', scope.row)">
                  编辑
                </el-button>
                <el-button size="medium" style="padding: 0" type="text" @click="handleDelete(scope.row)">删除
                </el-button>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @current-change="handleQuery"
          :current-page.sync="filter.page"
          :page-size="filter.size"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
        </div>
      <!-- <div v-if="customers.length > 0"> -->
        <!-- <el-pagination background @size-change="handleSizeChange" @current-change="handleQuery" :current-page.sync="filter.page" :page-sizes="[15, 30, 50, 100]" :page-size="filter.size" layout="total, sizes, prev, pager, next" :total="total"></el-pagination> -->
      <!-- </div> -->
    </div>
  </el-dialog>
</template>
<script>
import {tableStyle} from '@/util/mixins';
import { list} from '@/service/customer.js';
export default{
    // name: 'CustomerSelectDialog',
    data() {
        return {
          dialogVisible: false,
          searchKeyword: '',
          customers: [], // 这里存储从接口获取的所有客户数据
          filteredCustomers: [],// 根据搜索关键词过滤后的客户数据
          tableLoading:false,
          filter: {
            name: '',
            page: 1,
            size: 15
          },
          total: 0, // 数据总数count
        }
    },
    created () {
    this.handleQuery()
    },
    methods :{
      handleReset() {
        this.filter.name = '';
        this.handleQuery()
      },
      async handleQuery(val) {
        this.filter.page = 1;
        if (val) {
          this.filter.page = val;
        }
      this.tableLoading = true;
      const { success, data } = await list(this.filter);
      this.tableLoading = false;
      if (success) {
        this.customers = data.records;
        this.total = data.total;
      }
    },
    // 切换分页条数
      handleSizeChange(val) {
        this.filter.size = val;
        this.handleQuery();
      },
      handleSelection(){

      },
        // 选定商品
      handleCurrentChange (row) {
        debugger
        console.log("当前选中的数据"+JSON.stringify(row))
        if (row){
          this.$emit('ch-click', row)
        } 
        this.setCurrent()
      this.dialogVisible=false
      },
            // 清除当前选中
      setCurrent (row) {
        this.$refs.customerInfoTable.setCurrentRow(row)
      },
    },
    mixins: [tableStyle],
}
</script>
<style scoped lang="scss">
.p-header {
  .header-title {
    display: flex;
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;

    .title-right {
      display: inline-flex;

      span {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;

        img {
          width: 18px;
          height: 18px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }

      span:hover {
        cursor: pointer;
      }
    }
  }
}

::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

::v-deep .p-content {
  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 5px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.dialog-wrap {
  display: flex;
  height: 160px;
  flex-direction: column;

  .dialog-select {
    margin-bottom: 24px;

    label {
      width: 80px;
      text-align: left;
      display: inline-block;
    }

    label:before {
      content: '*';
      color: #f5222d;
      margin-right: 2px;
    }

    i {
      left: 140px;
      display: block;
      color: #f5222d;
      margin-top: 4px;
      position: absolute;
    }
  }

  .dialog-button {
    margin-top: 20px;
    margin-left: 110px;
  }

  .backImg {
    bottom: 0;
    width: 100%;
    opacity: 0.5;
    height: 44px;
    position: absolute;
  }
}
</style>