<template>
  <div class="select-wrap">
    <el-popover placement="bottom" trigger="click" v-model="focused">
      <ul>
        <li v-for="i in billingTypes" :key="i.value" @click="handleClick(i)">
          <span class="circular" :style="{'background':i.color}"></span>
          <span>{{i.label}}</span>
        </li>
      </ul>
      <div class="invoice-title" slot="reference">
        <span>{{info.label}}</span>
        <i class="el-icon-arrow-down"></i>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'InvoiceBillingTypeYL',
  props: ['billingChannel' ,'billingType'],
  data () {
    return {
      focused: false,
      billingTypes: [
        {
          color: '#1890FF',
          value: 'Dzpp',
          label: '增值税电子普票'
        },
        {
          color: '#1890FF',
          value: 'DZZP',
          label: '增值税电子专票'
        },
        {
          color: '#9D5224',
          value: 'Zzzp',
          label: '增值税纸质专票'
        },
        {
          color: '#249D38',
          value: 'Zzpp',
          label: '增值税纸质普票'
        },
        {
          color: '#97249D',
          value: 'SGDP',
          label: '收购发票(电子)'
        },
        {
          color: '#24279D',
          value: 'SGZP',
          label: '收购发票(纸质)'
        },
        {
          color: '#61249DFF',
          value: 'QDZP',
          label: '电子发票(增值税专用发票)'
        },
        {
          color: '#24279D',
          value: 'QDPP',
          label: '电子发票(普通发票)'
        }
      ],
      info: {
        value: 'Dzpp',
        label: '增值税电子普票',
      }
    }
  },
  watch:{
    billingType:{
      handler(val){
        let valType = this.billingTypes.filter(item => {
          return item.value == val
        })
        this.handleClick(valType[0])
      },
      immediate:true
    }
  },
  methods: {
    handleClick (val) {
      if (['SGDP', 'SGZP'].indexOf(val.value) > -1) {
        if (['NN-JG-JSP', 'HX-FWQ-JSP', 'LS-ZJ-UKEY'].indexOf(this.billingChannel) < 0) {
          this.toast('当前开票方式不支持开具收购发票', 'warning')
          this.focused = false
          return
        }
      }

      if (['DZZP'].indexOf(val.value) > -1) {
        if (['MB-JG-UKEY'].indexOf(this.billingChannel) < 0) {
          this.toast('当前开票方式不支持开具电子专票', 'warning')
          this.focused = false
          return
        }
      }

      this.info = val
      this.focused = false
      this.$emit('changeBillingType', val.value)
    },
    changeBilling (val) {
      let info = this.billingTypes.find(i => i.value === val)
      this.handleClick(info)
    }
  }
}
</script>

<style lang="scss" scoped>
  .select-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    width: auto;
    list-style: none;
    padding: 0px;

    li {
      padding: 8px;
      text-align: center;
      min-width: 120px;
    }

    .circular {
      display: inline-block;
      width: 10px;
      height: 10px;
      opacity: 0.5;
      border-radius: 50%;
      margin-right: 10px;
    }

    li:hover {
      cursor: pointer;
      background-color: #F2F6FC;
      color: #333333;
    }
  }

  .invoice-title {
    font-size: 32px;
    border-bottom: 4px double;
  }

  .invoice-title:hover {
    cursor: pointer;
    color: #3D94FF;
  }
</style>
