<template>
  <el-dialog
    append-to-body
    title="修改商品编码版本号"
    :visible.sync="visible"
    width="600px"
  >
    <el-form
      label-width="150px"
      size="mini"
      style="text-align: initial; padding: 0px 20px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="商品编码版本号：">
            <el-input
              v-model.trim="taxVersionNo"
              placeholder="请输入商品编码版本号"
              size="small"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button type="default" @click="handleCancel">取消</el-button>
      <el-button
        type="primary"
        @click="handleSubmit"
        :disabled="loading"
        :loading="loading"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { batchUpdateItem } from "@/service/sal/invoice-item";

export default {
  name: "InvoiceItemUpdateVersion",
  props: {
    invoices: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      taxVersionNo: undefined,
    };
  },
  methods: {
    // 打开交付
    open() {
      if (this.invoices.length < 1) {
        this.toast("请选择需要修改明细的发票！", "warning");
        return;
      }
      this.visible = true;
    },
    // 确认交付
    handleSubmit() {
      if (this.invoices.length < 1) {
        this.toast("请选择需要修改明细的发票！", "warning");
        return;
      }
      if (this.taxVersionNo == undefined || this.taxVersionNo == "") {
        this.toast("请输入要修改的商品编码版本号！", "warning");
        return;
      }
      this.loading = true;
      let invoiceIds = this.invoices.map((i) => i.id);
      batchUpdateItem({
        filter: { invoiceIds: invoiceIds },
        update: { taxVersionNo: this.taxVersionNo },
      })
        .then((res) => {
          if (res.success) {
            this.toast("修改成功", "success");
            this.visible = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 取消交付
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>
