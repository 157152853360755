<template>
  <div class="wrap">
    <el-table :data="dataList" stripe border v-loading="loading"
              :header-cell-style="handleHeaderCellStyle">
      <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
      <el-table-column label="组织名称" align="left" prop="orgName" min-width="180"></el-table-column>
      <el-table-column label="组织税号" align="left" prop="orgTaxNo" min-width="180"></el-table-column>
      <el-table-column label="开票设备" align="left" width="240">
        <template slot-scope="scope">
          <el-select v-model="scope.row.equipmentId"
                     :loading="scope.row.loading"
                     @change="handleEquipmentChange(scope.row)">
            <el-option v-for="equipment in scope.row.equipmentList"
                       :key="equipment.id"
                       :label="fmtEqpName(equipment)"
                       :value="equipment.id"></el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  getOrgEqpList
} from '@/service/system/own/setting'
import { setOrgOwnConfig, delOrgOwnConfig } from '@/service/system/config'

export default {
  name: 'BillingEquipmentSetting',
  data () {
    return {
      dataList: [],
      loading: false
    }
  },
  created () {
    this.getList()
  },
  methods: {
    handleEquipmentChange (row) {
      row.loading = true
      if (row.equipmentId == 0 || row.equipmentId == '0') {
        delOrgOwnConfig({ 'orgId': row.orgId, 'configs': { 'BILL_EQUIPMENT_ID': '' } }).then(res => {
          row.loading = false
          if (res.success) {
            this.$message.success('修改成功！')
            this.$emit('equipment-change', row)
          }
        })
      } else {
        setOrgOwnConfig({ 'orgId': row.orgId, 'configs': { 'BILL_EQUIPMENT_ID': row.equipmentId } }).then(res => {
          row.loading = false
          if (res.success) {
            this.$message.success('修改成功！')
            this.$emit('equipment-change', row)
          }
        })
      }
    },
    handleHeaderCellStyle () {
      return 'background-color:#EBF4FF;color:#333333;font-wight:400;'
    },
    getList () {
      this.loading = true
      getOrgEqpList().then(res => {
        this.loading = false
        if (res.success) {
          let records = res.data
          records.forEach(record => record.loading = false)
          this.dataList = records
        }
      })
    },
    fmtEqpName (equipment) {
      if (equipment.id == 0) {
        return '默认设备'
      }
      return `分机编号：${equipment.extensionNo}`
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .el-table td,
  ::v-deep .el-table th {
    text-align: initial !important;
    padding: 8px !important;;
  }

  ::v-deep .el-table .cell {
    height: initial !important;
    line-height: initial !important;
    overflow: initial !important;
  }

  ::v-deep .el-input {
    height: initial !important;
    line-height: initial !important;
    width: initial !important;
  }

  .wrap {
    padding: 10px 30px 50px 30px;
  }

</style>
