<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" label-width="100px" :inline="true" :model="queryForm">
          <el-form-item label="订单编号" prop="dataId">
            <el-input v-model.trim="queryForm.dataId" clearable placeholder="请输入订单编号" maxlength="32" @keyup.enter.native="handleGetBillingTradeList()" />
          </el-form-item>
          <el-form-item label="业务单号" prop="businessCode">
            <el-input v-model.trim="queryForm.businessCode" clearable placeholder="请输入业务单号" maxlength="32" @keyup.enter.native="handleGetBillingTradeList()" />
          </el-form-item>
          <el-form-item label="发票类型" prop="billingType">
            <el-select v-model="queryForm.billingType" clearable placeholder="请选择发票类型">
              <el-option v-for="item in invoiceType" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生成时间" v-show="selectExpended" prop="createdRange">
            <el-date-picker v-model="createdRange" type="daterange" range-separator="至" clearable start-placeholder="开始日期" end-placeholder="结束日期" size="mini" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item label="购方名称" v-show="selectExpended" prop="buyerName">
            <el-input v-model.trim="queryForm.buyerName" clearable placeholder="请输入购方名称" maxlength="64" @keyup.enter.native="handleGetBillingTradeList()" />
          </el-form-item>
          <el-form-item label="发票流水号" v-show="selectExpended" prop="billSerialNo">
            <el-input v-model="queryForm.billSerialNo" clearable placeholder="请输入发票流水号" maxlength="20" />
          </el-form-item>
          <el-form-item label="开票标识" v-show="selectExpended" prop="billingType">
            <el-select v-model="queryForm.billingFlag" clearable placeholder="请选择开票标识">
              <el-option v-for="item in billingFlagDatas" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="所属部门" v-show="selectExpended" prop="billingType">
            <el-select v-model="queryForm.deptCode" clearable placeholder="请选择所属部门">
              <el-option v-for="item in departmentList" :key="item.id" :label="item.name" :value="item.uniqueCode"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="数据来源" v-show="selectExpended" prop="source">
            <el-select v-model="queryForm.source" clearable placeholder="请选择数据来源">
              <el-option v-for="item in invoiceSource" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票主税率" prop="taxRate" v-show="selectExpended">
            <el-select v-model="queryForm.taxRate" clearable placeholder="请选择发票主税率">
              <el-option v-for="item in taxRates" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item class="invoice-tags" label="发票标签" v-show="selectExpended" prop="billingType">
            <el-select v-model="tags" filterable class="invoice-tags-select" multiple collapse-tags clearable placeholder="请选择发票标签">
              <el-option v-for="item in businessPractice" :key="item.code" :label="item.name" :value="item.code"></el-option>
            </el-select>
            <el-select v-model="queryForm.tagCondition" class="invoice-tags-condition" style="width: 50px" placeholder="请选择查询条件">
              <el-option label="且" value="AND"></el-option>
              <el-option label="或" value="OR"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item style="margin-left: 32px">
            <select-button show-status @list-close="handleListClose" @list-show="handleListShow" @select="handleQuery" @reset="handleReset"></select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <span>票据列表</span>
          <div>
            <!--            <el-button type="default" @click="handleTemplate">下载模板</el-button>-->
            <el-button type="default" @click="invExport">导出</el-button>
            <el-button type="default" style="width: 120px" @click="handleInvoiceTitle">修改开票抬头</el-button>
            <el-button type="default" style="width: 120px" @click="handleUpdateSellerInfo">同步销方信息</el-button>
            <el-button type="default" style="width: 120px" @click="dialogVisible = true">修改发票类型</el-button>
            <!-- <el-button type="default" style="width: 120px" @click="chanjetDialogVisible = true">畅捷通开票</el-button> -->
            <el-button type="default" @click="handleMergeInvoice">合并发票</el-button>
            <el-button type="primary" @click="handleBatchBill">批量开票</el-button>
            <el-button type="primary" @click="handleImport">导入开票</el-button>
            <el-button type="default" @click="handleBatchDelete">批量删除</el-button>
          </div>
        </div>
        <div class="content-table">
          <el-table border stripe :data="tableData" ref="retailTable" v-loading="recordsLoading" :row-key="handleGetRowKey" @row-click="handleOpenDetails" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelectionChange" style="width: 100%">
            <el-table-column type="selection" width="50" :reserve-selection="true" fixed="left" />
            <el-table-column type="index" label="序号" width="60" fixed="left" class-name="dl-pointer" />
            <el-table-column prop="created" label="生成时间" width="180" />
            <el-table-column prop="sumAmount" label="价税合计" width="120" :formatter="handleTableValueFormat" />
            <el-table-column prop="taxRate" label="税率" width="120" :formatter="handleTableValueFormat" />
            <el-table-column prop="billingType" label="发票类型" width="100" :formatter="handleTableValueFormat" />
            <el-table-column prop="buyerAddressTel" label="购买方地址电话" width="220" show-overflow-tooltip />
            <el-table-column prop="buyerBankAccount" label="购买方银行账号" width="220" show-overflow-tooltip />
            <el-table-column prop="openBillType" label="单据类型" width="110" :formatter="handleTableValueFormat" />
            <el-table-column prop="id" label="发票流水号" width="150" />
            <!-- <el-table-column prop="deptCode" label="部门" :formatter="handleTableValueFormat" width="150" /> -->
            <!-- <el-table-column prop="tags" label="发票标签" width="150" /> -->
            <!-- <el-table-column prop="tagsArr" label="发票标签" width="140">
              <template slot-scope="scope">
                <el-tag v-for="(item, index) in scope.row.tagsArr" style="margin-left: 12px" :key="index" type="info">{{ item.name }}</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column prop="source" label="数据来源" width="110" :formatter="handleTableValueFormat" />
            <el-table-column prop="dataId" label="订单编号" width="120" />
            <el-table-column prop="businessCode" label="业务单号" width="120" />
            <el-table-column prop="billingFlag" :formatter="handleTableValueFormat" label="开票标识" width="100" />
            <!-- <el-table-column prop="itemSize" label="明细条数" width="100" /> -->
            <el-table-column prop="operate" label="操作" fixed="right" width="180">
              <template slot-scope="scope">
                <el-button type="text" @click="handleCheckInvoiceLimit(scope.row)">开具</el-button>
                <el-button type="text" @click="handleInvoiceDelete(scope.row)">删除</el-button>
                <el-button type="text" @click="handleInvoicePreview(scope.row)">预览</el-button>
                <el-button type="text" @click="handelCopyInvoice(scope.row)">复制</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="table-statistics">
            <div class="statistics-left">
              <div>
                已勾选
                <span style="color: red">{{ mergeBillData.length }}</span
                >条
              </div>
              <div>金额：￥{{ selectedAmount }}</div>
              <div>税额：￥{{ selectedTaxAmount }}</div>
              <div>价税合计：￥{{ selectedSumAmount }}</div>
            </div>
            <div class="statistics-right">
              <div>总数量：{{ summary.total }}条</div>
              <div>总金额：￥{{ summary.amount }}</div>
              <div>总税额：￥{{ summary.taxAmount }}</div>
              <div>总价税合计：￥{{ summary.sumAmount }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页组件 -->
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="queryForm.page" :page-sizes="pageSizes" :page-size="queryForm.size" layout="total, sizes, prev, pager, next" :total="summary.total"></el-pagination>
      </div>
    </div>
    <!-- 发票拆分 -->
    <invoice-split ref="invoiceSplit" :invoiceId="invoiceId" :billingFrom="billingFrom" @success="handleInvoiceSplitSuccess" />
    <invoice-merge ref="invoiceMerge" :invoices="mergeBillData" @success="handleInvoiceSplitSuccess" />
    <!-- 发票开具 -->
    <invoice-bill v-if="issueVisible" :show.sync="issueVisible" KPJL="KPJL" :failRedInvoice="failRedInvoice" :invoice="issueData" @invoice-close="handleQuery" @billing-invoice="handleBillingInvoice"></invoice-bill>
    <!-- 提示弹窗 -->
    <el-dialog append-to-body title="提示" :visible.sync="titleVisible" width="30%">
      <div class="dialog-content">{{ titleContent }}</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="titleVisible = false">确定</el-button>
      </div>
    </el-dialog>
    <!-- 超限额提示弹窗 -->
    <el-dialog append-to-body title="超限额提醒" :visible.sync="limitVisible" width="510px">
      <div class="limit-dialog">
        <div class="data">
          当前设备开具{{ fmtBillingType(limit.billingType) }}限额：
          <span>{{ limit.limit }}</span>
        </div>
        <div class="message">当前待开发票价税合计超过税控设备开票限额，您可将开票信息进行拆分。</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleManualSplit">手动拆分</el-button>
        <el-button type="primary" @click="handleAutomaticSplit">自动拆分</el-button>
      </div>
    </el-dialog>
    <!-- 删除发票 -->
    <invoice-delete ref="invoiceDelete" :invoices="deleteInvoices" @success="handleDeleteSuccess" />
    <!-- 发票预览 -->
    <InvoicePreview :show.sync="invoicePreviewDialog" :YLSD="YLSD" :YL="YL" :pictLoading="pictLoading" :invoicedetailData="invoicedetailData" :invoiceInfoData="invoiceInfoData"></InvoicePreview>
    <!-- 更新抬头 -->
    <update-buyer :show.sync="invoiceTitleVisible" :invoice-title="invoiceTitle" @success="handleQuery" />
    <el-dialog append-to-body :title="billingReturnInfo" :visible.sync="billingReturnInfoVisible" @close="closeBillingReturnInfo" width="410px">
      <invoice-billing-return-info :printInfo="printInfo" :billingInfo="billingInfo" :buttonShow="buttonShow" @closeBillingReturnInfo="closeBillingReturnInfo" @navigateToAlready="navigateToAlready" :show.sync="issueVisible"></invoice-billing-return-info>
    </el-dialog>
    <!-- 发票导入 -->
    <el-dialog append-to-body :visible.sync="uploadVisible" width="680px" title="导入开票" :show-close="true">
      <invoice-upload-batch v-if="uploadVisible" @close="handleCloseUploadDialog"></invoice-upload-batch>
    </el-dialog>
    <!-- 批量开票 -->
    <el-dialog append-to-body :visible.sync="billBatchVisible" title="批量开票信息核对" width="358px" :show-close="false">
      <invoice-bill-batch v-if="billBatchVisible" :invoices="mergeBillData" @close="handleCloseBillDialog" @success="handleBatchBillSuccess"></invoice-bill-batch>
    </el-dialog>

    <el-dialog title="修改发票类型" :visible.sync="dialogVisible" width="30%">
      <div style="padding-bottom: 20px">
        <span style="font-size: medium;">发票类型：</span>
        <el-select v-model="updateType" placeholder="请选择">
          <el-option v-for="item in typeDatas" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitTypeUpdate">确认修改</el-button>
      </span>
    </el-dialog>

    <el-dialog title="畅捷通开票" :visible.sync="chanjetDialogVisible" width="30%">
      <div style="display: inline-block; padding-bottom: 20px">
        <span style="font-size: medium;">畅捷通单据编号：</span>
        <el-input v-model="voucherCode" placeholder="请输入畅捷通单据编号" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chanjetDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="syncChanjetVoucherData">确认开票</el-button>
      </span>
    </el-dialog>
    <!-- 复制弹窗 -->
    <invoice-copy ref="invoiceCopy" :invoice="checkData" @success="handelCopyInvoiceSuccess" />
  </div>
</template>

<script>
import { billInvoice, checkInvoiceLimit, getInventory, splitInvoiceLimit, syncChanjetVoucherDataInferface, updateBillingType, waitExport, waitList, waitSummary, waitTotal, systemLabelPage, updateInvoiceSellerInfo } from '@/service/sal/invoice';
import { ReqOrganizationTagsTree } from '@/service/organization';
import { getEquipmentByOrgId } from '@/service/invoice-open';
import { BILLING_TRADE_SOURCE, OPEN_INVOICE_TYPES } from '@/content/invoice.js';
import { changeMethods, commonsMethods, dateOption, tableStyle } from '@/util/mixins';
import InvoiceBill from '@/views/invoice-open/components/invoice-bill';
import UpdateBuyer from '@/views/invoice-open/components/update-buyer';
import InvoiceSplit from '@/views/invoice-open/components/invoice-split';
import InvoiceMerge from '@/views/invoice-open/components/invoice-merge';
import InvoiceDelete from '@/views/invoice-open/components/invoice-delete';
import InvoiceUploadBatch from './components/InvoiceUploadBatch';
import InvoiceBillBatch from './components/InvoiceBillBatch';
import InvoicePreview from './components/InvoicePreview';
import InvoiceBillingReturnInfo from '@/views/invoice-open/invoice-issue/components/InvoiceBillingReturnInfo';
import { fmtCurrency } from '@/assets/js/format-util.js';
import { selectOrganization } from '@/service/organization';
import { selectItems } from '@/service/sal/invoice';
import InvoiceCopy from '@/views/invoice-open/components/invoice-copy';
export default {
  name: 'RetailWaitInvoice',
  mixins: [tableStyle, dateOption, changeMethods, commonsMethods],
  components: {
    InvoiceCopy,
    InvoicePreview,
    InvoiceBill,
    UpdateBuyer,
    InvoiceSplit,
    InvoiceMerge,
    InvoiceDelete,
    InvoiceBillingReturnInfo,
    InvoiceUploadBatch,
    InvoiceBillBatch
  },
  props: ['ids'],
  data() {
    return {
      checkData: {
        id: '',
        invoiceCode: '',
        invoiceNo: '',
        billingType: '',
        amount: '',
        isDeliver: false,
        receiveEmail: ''
      },
      billingFlagDatas: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'Hp',
          label: '红票'
        },
        {
          value: 'Lp',
          label: '蓝票'
        }
      ],
      pictLoading: false,
      businessPractice: [],
      departmentList: [],
      departmentListS: [],
      invoicedetailData: [],
      invoicePreviewDialog: false,
      YL: '',
      YLSD: '',
      invoiceInfoData: {},
      updateType: '',
      voucherCode: '',
      typeDatas: [],
      dialogVisible: false,
      chanjetDialogVisible: false,
      confirmData: {},
      invoiceBusData: {},
      failRedInvoice: true,
      queryForm: {
        id: '',
        billSerialNo: undefined,
        buyerName: undefined,
        dataId: undefined,
        businessCode: undefined,
        billingType: undefined,
        orgId: undefined,
        source: undefined,
        taxRate: undefined,
        ids: [],
        page: 1,
        size: 15,
        billingFlag: '',
        tags: '',
        deptCode: '',
        tagCondition: 'OR'
      },
      actalSize: 0,
      tags: [],
      createdRange: [],
      dataTotal: 0,
      pageSizes: [15, 30, 50, 100],
      invoiceType: OPEN_INVOICE_TYPES,
      invoiceSource: BILLING_TRADE_SOURCE,
      tableData: [],
      isSplit: true,
      invoiceId: 0,
      billingFrom: '',
      // 合并单据数据区
      mergeBillData: [],
      //开具数据区
      issueVisible: false,
      issueData: {},
      billingChannel: '',
      issueDetailVisible: false,
      titleVisible: false,
      checkVisible: false,
      limitVisible: false,
      uploadVisible: false, // 批量导入发票弹窗
      billBatchVisible: false, // 批量开票弹窗
      limit: {},
      titleContent: '',
      forceUnlock: false,
      forceUnlockData: {},
      recordsLoading: false,
      invoiceTitleVisible: false,
      invoiceTitle: {},
      deleteInvoices: [],
      taxRates: [],
      selectExpended: false,
      openBillType: [],
      billingReturnInfo: '',
      billingReturnInfoVisible: false,
      printInfo: '',
      billingInfo: '',
      buttonShow: false,
      summary: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        sumAmount: 0
      },
      deleteCheckVisible: false,
      deleteCheckVisibleSplit: false
    };
  },
  watch: {
    // TODO 重构下拉筛选组织
    '$store.state.invoiceIssue.CurrentOrganization': {
      handler(value) {
        this.queryForm.orgId = value;
        this.systemLabelPage();
        setTimeout(() => {
          this.handleQuery();
        }, 300);
        this.handleInitEquipment();
        this.handlerElecType();
        this.ReqOrganizationTagsTree();
      },
      immediate: true
    },
    tags: {
      handler(value) {
        this.queryForm.tags = value.length ? value.join(',') : '';
        console.log(this.queryForm.tags, '---this.queryForm.tags----');
      }
    }
  },
  computed: {
    selectedAmount() {
      let amount = this.mergeBillData.map((i) => i.amount).reduce((num, sum) => Number(num) + Number(sum), 0);
      return amount.toFixed(2);
    },
    selectedTaxAmount() {
      let taxAmount = this.mergeBillData.map((i) => i.taxAmount).reduce((num, sum) => Number(num) + Number(sum), 0);
      return taxAmount.toFixed(2);
    },
    selectedSumAmount() {
      let sumAmount = this.mergeBillData.map((i) => i.sumAmount).reduce((num, sum) => Number(num) + Number(sum), 0);
      return sumAmount.toFixed(2);
    }
  },
  async created() {
    await this.handleInitDictionaries();
    await this.handleInitEquipment();
    await this.reqListDictionary();
    //await this.systemLabelPage()
    //await this.ReqOrganizationTagsTree()
  },
  mounted() {
    this.$bus.$on('handlerInvoiceData', (data) => {
      this.invoiceBusData = data;
    });
  },
  methods: {
    handelCopyInvoiceSuccess(invoice) {
      this.$emit('changeManual');
    },
    handelCopyInvoice(row) {
      this.checkData = row;
      setTimeout(() => {
        this.$refs.invoiceCopy.open();
      }, 0);
    },
    async ReqOrganizationTagsTree() {
      this.departmentList = [];
      let { success, data } = await ReqOrganizationTagsTree();
      if (success) {
        this.departmentList = [{ name: '全部', id: '' }, ...data];
        this.departmentListS = data;
      }
    },
    async systemLabelPage() {
      let res = await systemLabelPage({
        type: 'SALE',
        page: '1',
        size: '1000'
      });
      if (res.success) {
        this.businessPractice = res.data.records;
      }
    },
    async invExport() {
      let size;
      if (this.mergeBillData.length > 0) {
        size = this.mergeBillData.length;
        this.queryForm.ids = this.mergeBillData.map((item) => item.id);
      } else {
        const { success, data } = await waitTotal(this.queryForm);
        if (success) {
          size = data;
        } else {
          this.toast('查询失败', 'warning');
          return;
        }
      }
      // console.log(this.tableData.length, 'this.mergeBillData.length');

      if (this.tableData.length == '0') {
        this.toast('没有检测到可以导出的数据', 'warning');
        return;
      }
      this.$confirm('您是否要导出勾选/查询到的' + size + '张发票数据', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'info'
      }).then(async () => {
        const { success } = await waitExport(this.queryForm);
        if (success) {
          this.toast('操作成功', 'success');
        }
      });
    },
    async handlerElecType() {
      this.typeDatas = [
        {
          value: 'Dzpp',
          label: '电子普票'
        },
        {
          value: 'DZZP',
          label: '电子专票'
        },
        {
          value: 'Zzpp',
          label: '纸质普票'
        },
        {
          value: 'Zzzp',
          label: '纸质专票'
        },
        {
          value: 'SGDP',
          label: '收购电票'
        },
        {
          value: 'SGZP',
          label: '收购纸票'
        }
      ];
      const { success, data } = await selectOrganization(this.queryForm.orgId);
      if (success && data.isElec) {
        this.typeDatas = [
          {
            value: 'QDZP',
            label: '电子发票（增值税专用发票）'
          },
          {
            value: 'QDPP',
            label: '电子发票（普通发票）'
          }
        ];
      }
    },
    async submitTypeUpdate() {
      if (this.mergeBillData.length === 0) {
        this.toast('请选择要修改类型的数据', 'warning');
        return;
      }
      let ids = this.mergeBillData.map((i) => i.id);
      const { success } = await updateBillingType({
        ids: ids,
        billingType: this.updateType
      });
      if (success) {
        this.toast('操作成功', 'success');
        this.dialogVisible = false;
        this.handleQuery();
      }
    },
    async syncChanjetVoucherData() {
      if (this.voucherCode == null) {
        this.toast('请输入畅捷通单据编号', 'warning');
        return;
      }

      const { success } = await syncChanjetVoucherDataInferface(this.voucherCode);

      if (success) {
        this.toast('操作成功', 'success');
        this.chanjetDialogVisible = false;
        this.handleQuery();
      }
    },
    // 初始化开票设备
    async handleInitEquipment() {
      if (!this.queryForm.orgId) {
        return;
      }
      const { success, data } = await getEquipmentByOrgId({ orgId: this.queryForm.orgId });
      if (success) {
        this.billingChannel = data.billingChannel;
      }
    },
    // 初始化字典值
    handleInitDictionaries() {
      this.getDicts('open_bill_type').then(({ data }) => (this.openBillType = data));
    },
    // 收起筛选列表
    handleListClose() {
      this.selectExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.selectExpended = true;
    },
    // 重置筛选列表
    handleReset() {
      this.resetForm('queryForm');
      this.tags = '';
      this.queryForm.tags = '';
      this.queryForm.deptCode = '';
      this.queryForm.billingFlag = '';
      this.createdRange = '';
    },
    // 表格值格式化
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '金额':
        case '税额':
        case '价税合计':
          return fmtCurrency(value, 2, '');
        case '税率':
          return isNaN(value) ? value : value == '' ? '' : `${value * 100}%`;
        case '开票标识':
          if (row.billingFlag == 'Lp') {
            return '蓝票';
          } else {
            return '红票';
          }
        case '部门':
          return this.transition(this.departmentListS, value);
        case '发票类型':
          return this.handleValueToLabel('BillingType', value);
        case '业务类型':
          return this.handleValueToLabel('BillingFrom', value);
        case '单据类型':
          return this.getDictLabel(this.openBillType, value);
        case '数据来源':
          return this.handleValueToLabel('SystemSource', value);
        default:
          return value;
      }
    },
    transition(type, value) {
      return this.FmtEnum(type, value);
    },
    FmtEnum(enumState = [], enumValue) {
      const enumItem = enumState.find((item) => item.uniqueCode == enumValue);
      if (enumItem) return enumItem.name;
      return '--';
    },
    // 条件查询
    async handleQuery() {
      this.cancelSelectionChangeBatch();
      this.actalSize = this.queryForm.size;

      await this.handleOriginalOrderStatistics();
      this.dataTotal = this.summary.total;
      if (this.summary.total == 0) {
        this.tableData = [];
      }
      let offset = (this.queryForm.page - 1) * this.queryForm.size;
      if (this.summary.total > offset) {
        if (this.summary.total - offset < this.queryForm.size) {
          this.actalSize = this.summary.total - offset;
        }
        this.handleGetBillingTradeList();
      }
    },
    /* 待开数据统计 */
    async handleOriginalOrderStatistics() {
      this.dataLoading = true;
      const { success, data } = await waitSummary(this.addDateRange(this.queryForm, this.createdRange, 'Created'));
      if (success) {
        this.summary = data[0];
      }
      this.dataLoading = false;
    },
    // 批量删除
    handleBatchDelete() {
      this.deleteInvoices = [...this.mergeBillData];
      setTimeout(() => {
        this.$refs.invoiceDelete.open();
      }, 0);
    },
    // 删除成功
    handleDeleteSuccess() {
      this.handleQuery();
    },
    // 批量开具
    handleBatchBill() {
      if (this.mergeBillData.length < 1) {
        this.toast('请至少勾选一条发票', 'warning');
        return;
      }
      this.billBatchVisible = true;
    },
    reqListDictionary() {
      this.getDicts('sys_invoice_tax_rate').then(({ data }) => (this.taxRates = data));
    },
    // 待开发票列表获取
    async handleGetBillingTradeList() {
      this.recordsLoading = true;
      if (this.ids) {
        this.queryForm.ids = JSON.parse(this.ids);
      }
      let queryParams = JSON.parse(JSON.stringify(this.addDateRange(this.queryForm, this.createdRange, 'Created')));
      if (this.actalSize != 0) {
        queryParams = Object.assign(queryParams, { size: this.actalSize });
      }
      const { success, data } = await waitList(queryParams);
      this.recordsLoading = false;
      if (success) {
        const newData = data.records.map((item) => {
          const tags = item.tags.split(',');
          item.tagsArr = this.businessPractice.filter((item) => {
            return tags.includes(item.code);
          });
          return item;
        });
        // console.log(newData);
        this.tableData = newData;
        // this.tableData = data.records;
        this.dataTotal = data.total;
      }
    },
    // 发票开具
    async handleBillingInvoice(printable, back) {
      //判断连开带打的标识
      if (printable == 'Y') {
        this.issueData.printable = true;
      } else {
        this.issueData.printable = false;
      }
      const { success, data } = await billInvoice({
        id: this.issueData.id,
        printable: this.issueData.printable,
        ...this.invoiceBusData
      });
      if (success) {
        if (data.isSync) {
          this.billingInvoiceId = data.billingInvoiceId;

          if (data.isPrint) {
            if (data.billingState) {
              this.billingReturnInfo = '发票开具成功！';
              this.billingReturnInfoVisible = true;
              if (data.printState) {
                this.printInfo = '正在驱动打印机执行打印操作，请稍后......';
              } else {
                this.printInfo = '驱动打印机失败：根据接口返回原因进行展示';
              }
            } else {
              this.printInfo = '错误原因：根据接口返回原因进行展示！';
              this.billingInfo = '你可以在"开票记录"中重试。';
              this.billingReturnInfo = '发票开具失败！';
              this.buttonShow = true;
              this.billingReturnInfoVisible = true;
            }
          }
        }
        if (back) back(success);
      }
      this.handleGetBillingTradeList();
    },
    // 选中数据
    handleSelectionChange(invoices) {
      this.mergeBillData = invoices;
    },
    // 取消选中
    cancelSelectionChangeBatch() {
      this.mergeBillData = [];
      let retailTable = this.$refs.retailTable;
      if (retailTable) retailTable.clearSelection();
    },
    // 合并发票
    handleMergeInvoice() {
      setTimeout(() => {
        this.$refs.invoiceMerge.open();
      }, 0);
    },
    handleCloseUploadDialog() {
      this.uploadVisible = false;
      this.handleQuery();
    },
    // 导入开票
    handleImport() {
      this.uploadVisible = true;
    },
    // 发票明细
    handleOpenDetails(row, column) {
      if (['序号'].indexOf(column.label) == -1) {
        if ([undefined, '操作'].indexOf(column.label) == -1) {
          this.toast('点击“序号”列表区域，查看该票明细信息！', 'info');
        }
        return;
      }

      this.invoiceId = row.id;
      this.billingFrom = row.billingFrom;
      setTimeout(() => {
        this.$refs.invoiceSplit.open();
      }, 0);
    },
    // 明细弹窗关闭
    handleInvoiceSplitSuccess() {
      this.$refs.retailTable.clearSelection();
      this.handleQuery();
    },
    // 切换分页条数
    handleSizeChange(value) {
      this.queryForm.size = value;
      this.handleQuery();
    },
    // 翻页
    handleCurrentChange(value) {
      this.queryForm.page = value;
      this.handleQuery();
    },
    // 记住选中
    handleGetRowKey(row) {
      return row.id;
    },
    // 发票限额校验
    async handleCheckInvoiceLimit(invoice) {
      const { success, data } = await checkInvoiceLimit(invoice);
      if (success && data.success) {
        this.handleInvoiceIssue(invoice);
        return;
      }
      this.limitVisible = true;
      this.limit = { ...invoice, ...data };
    },
    // 发票开具
    handleInvoiceIssue(invoice) {
      if (invoice.sumAmount == 0) {
        this.toast('当前价税合计为0，无法开具！', 'warning');
        return;
      }
      this.issueVisible = true;
      this.issueData = invoice;
      this.issueData['billingChannel'] = this.billingChannel;
    },
    // 手动拆分
    handleManualSplit() {
      this.limitVisible = false;
      this.invoiceId = this.limit.id;
      setTimeout(() => {
        this.$refs.invoiceSplit.open();
      }, 0);
    },
    // 自动拆分
    handleAutomaticSplit() {
      this.limitVisible = false;

      splitInvoiceLimit(this.limit.id).then((res) => {
        if (res.success) {
          this.handleQuery();
          this.toast('拆分发票成功！', 'success');
        }
      });
    },
    // 批量下载发票
    batchDownloadInvoice(invoices) {
      let invoice = invoices.find((invoice) => invoice.sumAmount() == 0);
      if (invoice != null) {
        this.toast('当前结算单中存在价税合计为0数据,无法开具', 'warming');
        return;
      }
      let loading = this.handleLoading();
      getInventory({
        orgId: invoice.orgId,
        equipmentId: invoice.equipmentId,
        billingType: invoice.billingType
      }).then((res) => {
        loading.close();
        if (res.success) {
          this.issueVisible = true;
          this.issueData = invoice;
          this.issueData.totals = res.data.residueQuantity;
          this.issueData.invoiceCode = res.data.invoiceCode;
          this.issueData.invoiceNo = res.data.invoiceNo;
        }
      });
    },
    async handleUpdateSellerInfo() {
      if (this.mergeBillData.length == 0) {
        this.toast('请选择需要同步的发票信息', 'warning');
        return;
      }
      let param = {
        ids: this.mergeBillData.map((i) => i.id)
      };
      const rsp = await updateInvoiceSellerInfo(param);
      if (rsp.success) {
        this.toast('同步销方信息成功', 'success');
      }
    },
    // 发票抬头修改
    handleInvoiceTitle() {
      if (this.mergeBillData.length != 1) {
        this.toast('请选择一条要修改的发票数据', 'warning');
        return;
      }
      let invoice = this.mergeBillData[0];
      let invoiceStatus = invoice.invoiceStatus;
      if (!(invoiceStatus == 'KJSB' || invoiceStatus == 'WKP' || invoiceStatus == 'WSC')) {
        this.toast('所选择发票的发票状态不允许修改开票抬头信息', 'warning');
        return;
      }
      this.invoiceTitle = JSON.parse(JSON.stringify(invoice));
      this.invoiceTitleVisible = true;
    },
    //发票预览
    handleInvoicePreview(row) {
      this.invoicedetailData = [];
      if (row.billingType == 'QDZP' || row.billingType == 'QDPP') {
        this.YLSD = 'YLSD';
      } else {
        this.YLSD = '';
      }
      this.pictLoading = true;
      this.invoicePreviewDialog = true;
      this.YL = 'YL';
      this.invoiceInfoData = row;
      this.selectItems(row.id);
    },
    async selectItems(id) {
      let res = await selectItems(id);
      if (res.success) {
        this.invoicedetailData = res.data;
        this.invoicedetailData.forEach((item, index) => {
          item.taxRateModel = item.taxRate * 100 + '%';
          item.merchandiseName = `*${item.taxClassName}*${item.merchandiseName}`;
          item.index = index;
        });
        this.pictLoading = false;
      }
    },
    // 发票删除检查
    handleInvoiceDelete(invoice) {
      this.deleteInvoices = [invoice];
      setTimeout(() => {
        this.$refs.invoiceDelete.open();
      }, 0);
    },
    fmtBillingType(val) {
      return this.handleValueToLabel('BillingType', val);
    },
    closeBillingReturnInfo() {
      this.handleQuery();
      this.issueVisible = false;
      this.billingReturnInfoVisible = false;
    },
    handleBatchBillSuccess() {
      this.handleQuery();
    },
    handleCloseBillDialog() {
      this.billBatchVisible = false;
    },
    navigateToAlready() {
      this.issueVisible = false;
      this.billingReturnInfoVisible = false;
      this.$emit('navigateToAlready');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/select.scss';

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    span {
      font-size: 16px;
      color: #666666;
    }

    .el-button {
      width: 80px;
      padding: 0;
    }
  }

  .content-table {
    .el-button {
      padding: 0;
    }
    .table-statistics {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ebf4ff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;

      .statistics-left {
        display: flex;
        justify-content: space-between;

        div {
          margin-right: 20px;
        }

        div:first-child {
          margin-left: 10px;
        }
      }

      .statistics-right {
        display: flex;
        justify-content: space-between;

        div {
          margin-left: 20px;
        }

        div:last-child {
          margin-right: 10px;
        }
      }
    }
  }
}

.dialog-content {
  margin-top: -6px;
  margin-left: 24px;
  margin-right: 24px;
  text-align: left;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-body {
  margin: -16px 24px 0 24px;

  .body-top {
    .top-first-row {
      display: flex;
      margin-bottom: 12px;
    }

    .top-second-row {
      display: flex;
      text-align: left;
      margin-bottom: 24px;

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 20em;
      }
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .body-bottom {
    text-align: left;
    border-top: 1px solid #e9e9e9;
    margin: 0 -24px;
    padding: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}

.limit-dialog {
  margin-top: -20px;
  padding-left: 20px;
  text-align: left;
  font-size: 14px;

  .data {
    padding-bottom: 25px;
    border-bottom: 1px solid #e9e9e9;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }
  }

  .message {
    padding: 24px 0;
    color: #f5222d;
  }
}

.el-dialog__footer {
  padding: 10px 24px 24px;
  text-align: right;
  box-sizing: border-box;
}
.select-content {
  ::v-deep .el-select .el-tag {
    max-width: 70%;
  }
  ::v-deep .select-form {
    width: auto;
    display: block;
  }

  ::v-deep .select-form > div {
    display: inline-flex;
  }
}
::v-deep .el-range-editor--mini .el-range-separator {
  line-height: inherit;
}
</style>
<style lang="scss">
.el-table__row td.dl-pointer {
  cursor: pointer;
  color: #409eff;
}
.invoice-tags {
  .invoice-tags-select {
    input {
      height: 32px !important;
    }
  }
  .invoice-tags-condition {
    .el-input {
      width: 60px;
    }
  }
}
</style>
