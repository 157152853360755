<template>
  <div>
    <!-- 单票交付 -->
    <el-dialog append-to-body title="确认交付发票信息" :visible.sync="singleVisible" width="600px">
      <el-form label-width="100px" size="mini" style="text-align: initial; padding: 0px 20px;">
        <el-row>
          <el-col :span="24">
            <el-form-item label="发票类型：">{{ fmtBillingType(invoice.billingType) }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票代码：">{{ invoice.invoiceCode }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票号码：">{{ invoice.invoiceNo }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票金额：">￥{{ invoice.sumAmount }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票税额：">￥{{ invoice.taxAmount }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="交付邮箱：">
              <el-input v-model.trim="receiveEmail" placeholder="请输入交付邮箱" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="交付短信：">
              <el-input v-model.trim="receivePhone" placeholder="请输入交付短信" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="default" @click="handleCancelDeliver">取消</el-button>
        <el-button type="primary" @click="handleConfirmDeliver" :disabled="delivering" :loading="delivering">交付</el-button>
      </div>
    </el-dialog>
    <!-- 批量交付 -->
    <el-dialog append-to-body title="确认交付发票信息" :visible.sync="batchVisible" width="600px">
      <el-form label-width="100px" size="mini" style="text-align: initial; padding: 0px 20px;">
        <el-row>
          <el-col :span="24">
            <el-form-item label="购方名称："><span style="font-weight: bold;">{{ buyerName }}</span></el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="发票张数："><span style="font-weight: bold;">{{ invoices.length }}</span></el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="交付邮箱：">
              <el-input v-model.trim="receiveEmail" placeholder="请输入交付邮箱" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <span style="padding-left: 20px; font-size: 14px; color: red;">注意：交付邮箱为空时，默认使用发票原设置接收邮箱交付！</span>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="default" @click="handleCancelDeliver">取消</el-button>
        <el-button type="primary" @click="handleConfirmDeliver" :disabled="delivering" :loading="delivering">交付
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib'
import { deliverInvoice } from '@/service/sal/invoice'

export default {
  name: 'InvoiceSend',
  props: {
    invoices: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data () {
    return {
      // 单张交付弹窗
      singleVisible: false,
      // 批量交付弹窗
      batchVisible: false,
      // 发票正在交付
      delivering: false,
      // 交付接收邮箱
      receiveEmail: undefined,
      // 交付手机
      receivePhone: undefined,
      buyerName: undefined
    }
  },
  computed: {
    // 首张发票
    invoice () {
      if (this.invoices.length < 1) return {}
      return this.invoices[0]
    }
  },
  methods: {
    // 打开交付
    open () {
      if (this.invoices.length < 1) {
        this.toast('请选择需要交付的发票信息！', 'warning')
        return
      } else {
        // 电票校验
        if (this.invoices.map(i => i.billingType).findIndex(i => ['Zzpp', 'Zzzp'].indexOf(i) > -1) > -1) {
          this.toast('所选发票包含非电子发票！', 'warning')
          return
        }
        let typeArr1 = this.invoices.filter(i => ['BW-XML-SKP', 'HX-XML-JSP'].indexOf(i.billingChannel) > -1)
        // 单类校验
        if (new Set(typeArr1).size >= 1) {
          this.toast('包含百旺/航信-XML开票数据，请使用线下交付！', 'warning')
          return
        }

        if (this.invoices.length == 1) {
          this.receiveEmail = this.invoices[0].receiveEmail
          this.singleVisible = true
        } else {
          let arr = this.invoices.map(i => i.buyerName);
          const buyerNames = Array.from(new Set(arr));
          if(buyerNames.length > 1){
            this.toast('购方名称不一致', 'warning')
            return
          }
          this.buyerName = buyerNames[0];
          this.batchVisible = true
        }
      }
    },
    // 确认交付
    async handleConfirmDeliver () {
      await this.requestDeliver()
    },
    // 取消交付
    handleCancelDeliver () {
      if (this.singleVisible) this.singleVisible = false
      if (this.batchVisible) this.batchVisible = false
    },
    // 请求交付
    async requestDeliver () {
      if (this.invoices.length > 1 && this.receiveEmail != undefined && this.receiveEmail != '') {
        if (this.receiveEmail == undefined || this.receiveEmail == '') {
          this.toast('填写要交付的邮箱地址！', 'warning')
          return
        }
        if (!RegexLib.Email.test(this.receiveEmail)) {
          this.toast('填写的邮箱格式不正确！', 'warning')
          return
        }
      }
      this.delivering = true
      let ids = this.invoices.map(i => i.id)
      const { success } = await deliverInvoice({
        'invoiceIds': ids,
        'receiveEmail': this.receiveEmail,
        'receivePhone': this.receivePhone
      })
      this.delivering = false
      if (success) {
        if (this.singleVisible) this.singleVisible = false
        if (this.batchVisible) this.batchVisible = false
        this.toast('交付成功，请您查收', 'success')
      }
    },
    // 格式化开票方式
    fmtBillingType (val) {
      return this.handleValueToLabel('BillingType', val)
    }
  }
}
</script>

<style scoped lang="scss">
  .el-form-item {
    margin-bottom: 22px;
  }

  ::v-deep .el-form-item__label, ::v-deep .el-form-item__content {
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-input__inner {
    height: 40px;
    line-height: 40px;
  }
</style>
