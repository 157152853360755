<template>
  <el-dialog append-to-body
             title="确认作废发票信息"
             :visible.sync="revokeVisible"
             :show-close="false"
             width="30%">
    <div class="dialog-main">
      <label-span label="发票类型：">
        <span>{{handleValueToLabel('BillingType',invoice.billingType)}}</span>
      </label-span>
      <label-span label="发票代码：">
        <span>{{invoice.invoiceCode}}</span>
      </label-span>
      <label-span label="发票号码：">
        <span>{{invoice.invoiceNo}}</span>
      </label-span>
      <label-span label="开票金额：">
        <span>{{'￥'+ invoice.sumAmount}}</span>
      </label-span>
<!--      <div v-if="['BW-XML-SKP', 'HX-XML-JSP'].indexOf(invoice.billingChannel) > -1">-->
<!--        <span>提示信息：</span>-->
<!--        <span class="tipMessage">点击“作废”前请确保该发票已经作废（该张发票的开票方式需要线下作废发票，将作废成功状态同步至本系统）。</span>-->
<!--      </div>-->
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="default" @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="handleRevoke">作废</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { revokeInvoice } from '@/service/sal/invoice'

export default {
  name: 'InvoiceRevoke',
  props: {
    invoice: {
      type: Object
    }
  },
  data () {
    return {
      revokeVisible: false
    }
  },
  methods: {
    // 打开窗口
    open () {
      this.revokeVisible = true
    },
    // 发票作废
    handleRevoke () {
      revokeInvoice(this.invoice.id).then(res => {
        if (res.success) {
          this.revokeVisible = false
          this.$message.success('发票作废成功')
          this.$emit('success')
        }
      })
    },
    // 取消操作
    handleCancel () {
      this.revokeVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialog-main {
    margin-top: -24px;
    margin-left: 24px;
    text-align: left;

    .label-span {
      margin-bottom: 14px;

      span {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
      }
    }

    .dialog-split-line {
      height: 1px;
      background: #E9E9E9;
      margin: 24px 0px 24px -24px;
    }

    .tipMessage {
      color: red;
    }

    span {
      width: 42px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-right: 10px;
    }
  }
</style>
