<template>
  <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :visible.sync="toredVisible"
             append-to-body title="确认冲红发票信息" width="30%">
    <div class="dialog-main">
      <label-span v-if="invoice.billingType != 'QDZP' && invoice.billingType != 'QDPP'" label="发票代码：">
        <span>{{ invoice.invoiceCode }}</span>
      </label-span>
      <label-span label="发票号码：">
        <span style="margin-left: 40px">{{ invoice.invoiceNo }}</span>
      </label-span>
      <div v-if="isZyp">
        <span>红字通知单号：</span>
        <el-input v-model.trim="invoice.redNoticeNo" placeholder="请输入红字通知单号"></el-input>
      </div>
      <div style="margin-top: 10px; display: flex; flex-direction: row; justify-content: flex-start" v-if="isNeedToRedDes">
        <span style="width: 80px">冲红原因：</span>
        <el-select v-model="invoice.toRedRemark" placeholder="请选择" style="margin-left: 18px">
          <el-option v-for="item in toredReasons" :key="item.id" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="dialog-split-line"></div>
      <label-span label="发票类型：">
        <span>{{ handleValueToLabel('BillingType', invoice.billingType) }}</span>
      </label-span>
      <div v-if="!isDzp" v-loading="inventoryLoading">
        <label-span label="发票代码：">
          <span>{{ inventory.invoiceCode }}</span>
        </label-span>
        <label-span label="发票号码：">
          <span>{{ inventory.invoiceNo }}</span>
        </label-span>
      </div>
      <label-span label="开票金额：">
        <span>￥ -{{ invoice.sumAmount }}</span>
      </label-span>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button type="default" @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="handleTored">冲红</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {getInventory, toredInvoice} from '@/service/sal/invoice';
import {redOffsetEntry} from '@/service/sal/invoice.js';

export default {
  name: 'InvoiceTored',
  props: {
    invoice: {
      type: Object
    }
  },
  data() {
    return {
      toredVisible: false,
      entryValueRed: false,
      redNoticeUuid: '',
      inventory: {
        invoiceCode: undefined,
        invoiceNo: undefined
      },
      inventoryLoading: false,
      toredReasons: [
        {label: '开票有误', value: '01'},
        {label: '销货退回', value: '02'},
        {label: '服务中止', value: '03'},
        {label: '销售折让', value: '04'}
      ],
      isentryValue: {}
    };
  },
  computed: {
    isDzp() {
      return ['Dzpp', 'DZZP', 'SGDP', 'QDZP', 'QDPP'].indexOf(this.invoice.billingType) != -1;
    },
    isZyp() {
      return ['Zzzp', 'DZZP', 'QDZP'].indexOf(this.invoice.billingType) != -1;
    },
    isNeedToRedDes() {
      return this.invoice.billingChannel == 'QD-XSD'
          || this.invoice.billingChannel == 'QD-RPA'
          || this.invoice.billingChannel == 'LS-ZJ-UKEY'
          || this.invoice.billingChannel == 'HX-ZJ-JSP';
    }
  },
  methods: {
    // async redOffsetEntry(){
    //   let res = await redOffsetEntry({id:this.invoice.id})
    //   this.isentryValue = res
    //   console.log(res,'res')
    //   if(!res.success){
    //     debugger;
    //     this.$message.warning(res.message);
    //     return;
    //   }
    // },
    open() {
      if (['BW-XML-SKP', 'HX-XML-JSP'].indexOf(this.invoice.billingChannel) > -1) {
        this.toast('该票是百旺/航信-XML开具，请在开票软件中冲红！', 'warning');
        return;
      }
      if (this.invoice.billingChannel == 'QD-LQ') {
        redOffsetEntry({id: this.invoice.id}).then((res) => {
          console.log(res, 'res,');
          this.redNoticeUuid = res.data.redNoticeUuid;
          if (!res.success) {
            return;
          } else {
            this.toredVisible = true;
            this.entryValueRed = true;
            if (!this.isDzp) {
              this.inventoryLoading = true;
              getInventory({
                orgId: this.invoice.orgId,
                equipmentId: this.invoice.equipmentId,
                billingType: this.invoice.billingType
              }).then(({success, data}) => {
                if (success) {
                  this.inventory = data;
                }
                this.inventoryLoading = false;
              });
            }
          }
        });
      } else {
        this.toredVisible = true;
        if (!this.isDzp) {
          this.inventoryLoading = true;
          getInventory({
            orgId: this.invoice.orgId,
            equipmentId: this.invoice.equipmentId,
            billingType: this.invoice.billingType
          }).then(({ success, data }) => {
            if (success) {
              this.inventory = data;
            }
            this.inventoryLoading = false;
          });
        }
      }
    },
    /* 发票冲红确认 */
    handleTored() {
      // 红字通知单号校验
      if (this.isZyp && !this.invoice.redNoticeNo) {
        this.$message.warning('红字通知单号不允许为空！');
        return;
      }
      if (this.isNeedToRedDes && !this.invoice.toRedRemark) {
        this.$message.warning('冲红原因不允许为空！');
        return;
      }
      toredInvoice({
        invoiceId: this.invoice.id,
        receiveEmail: this.invoice.receiveEmail,
        redNoticeNo: this.invoice.redNoticeNo,
        toRedRemark: this.invoice.toRedRemark,
        redNoticeUuid: this.redNoticeUuid
      }).then((res) => {
        if (res.success) {
          this.toredVisible = false;
          this.entryValueRed = false;
          this.$message.success('发票冲红成功');
          this.$emit('success');
        }
      });
    },
    handleCancel() {
      this.toredVisible = false;
      this.entryValueRed = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-main {
  margin-top: -20px;
  margin-left: 24px;
  text-align: left;

  .label-span {
    margin-bottom: 14px;

    span {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
    }
  }

  .dialog-split-line {
    height: 1px;
    background: #e9e9e9;
    margin: 24px 0px 24px -24px;
  }

  span {
    width: 42px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-right: 10px;
  }
}
</style>
