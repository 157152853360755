<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-body">
        <el-form class="select-form" ref="queryForm" :model="queryForm" label-width="80px">
          <el-input
            class="select-input scan-input"
            style="cursor: pointer"
            v-model.trim="queryForm.buyerName"
            placeholder="请输入想要查询的企业名称"
            maxlength="50"
            @keyup.enter.native="handleQuery"
          >
            <i
              slot="suffix"
              @click="handleQuery"
              class="el-input__icon el-icon-search"
            ></i>
          </el-input>
        </el-form>
      </div>
    </div>
    <div class="content-main" style="margin-top: 0; padding-top: 0">
      <div v-if="tableData.length > 0">
        <div class="content-table" style="width: 100%">
          <el-table
            border
            stripe
            height="300"
            :data="tableData"
            ref="multipleTable"
            v-loading="recordsLoading"
            align="center"
            :header-cell-style="handleHeaderCellStyle"
          >
            <el-table-column
              fixed
              prop="buyerName"
              label="购方名称"
              width="180"
            >
              <template scope="scope">
                <el-link
                  @click="handleScanWaitInvoice(scope.row)"
                  type="primary"
                >
                  {{ scope.row.buyerName }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="buyerTaxNo"
              label="纳税人识别号"
              :formatter="handleTableValueFormat"
              width="250"
            ></el-table-column>
            <el-table-column
              prop="billingType"
              label="开票类型"
              :formatter="handleTableValueFormat"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="buyerAddressTel"
              label="地址/电话"
              :formatter="handleTableValueFormat"
              show-overflow-tooltip
              min-width="220"
            ></el-table-column>
            <el-table-column
              prop="buyerBankAccount"
              label="银行账号"
              :formatter="handleTableValueFormat"
              width="170"
            ></el-table-column>
            <el-table-column
              prop="email"
              label="电子邮箱"
              :formatter="handleTableValueFormat"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="phone"
              label="手机号码"
              :formatter="handleTableValueFormat"
              width="200"
            ></el-table-column>
          </el-table>
        </div>
        <!-- 分页组件 -->
        <div class="content-page">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryForm.page"
            :page-sizes="pageSizes"
            :page-size="queryForm.size"
            layout="total, sizes, prev, pager, next"
            :total="dataTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div
        v-else
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 351px;
        "
      >
        <div style="text-align: center; color: #dce0e8">
          <img
            style="width: 100px; height: 100px"
            src="../../../../../assets/img/cooperate/scanning-noData.png"
          />
          <div v-if="disposeStatus === 'WCL'">当前没有未处理数据</div>
          <div v-if="disposeStatus === 'YCL'">当前没有已处理数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  tableStyle,
  dateOption,
  changeMethods,
  commonsMethods,
} from "@/util/mixins";
import { getScanWaitList, editScanWaitStatus } from "@/service/invoice-open";
export default {
  name: "ScanWaitBillInvoice",
  mixins: [tableStyle, dateOption, changeMethods, commonsMethods],
  props: {},
  data() {
    return {
      tableData: [],
      page: {
        total: 0,
        size: 15,
        current: 1,
      },
      queryForm: {
        buyerName: "",
        disposeStatus: "",
        page: 1,
        size: 15
      },
      dataTotal: 0,
      pageSizes: [15, 30, 50, 100],
      recordsLoading: false,
      disposeStatus: "WCL",
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    /* 条件查询 */
    handleQuery() {
      this.handleGetScanWaitList();
    },
    /* 对账单明细列表获取 */
    async handleGetScanWaitList() {
      this.recordsLoading = true;
      this.queryForm.disposeStatus = this.disposeStatus;
      getScanWaitList(this.queryForm).then((res) => {
        if (res.success) {
          let list = res.data.records;
          this.tableData = list;
          this.dataTotal = res.data.total;
        }
        this.recordsLoading = false;
      });
    },
    /* 处理扫码待开发票状态 */
    async editScanWaitStatus(id, isClean) {
      this.recordsLoading = true;
      editScanWaitStatus({
        id: id,
        isClean: isClean,
        buyerName: this.queryForm.buyerName,
        disposeStatus: 'WCL',
        newDisposeStatus: 'YCL',
      }).then((res) => {
        if (res.success) {
          if (isClean === "Y") {
            this.tableData = [];
          } else {
            this.handleGetScanWaitList();
          }
        }
        this.recordsLoading = false;
      });
    },
    handleScanWaitInvoice(row) {
      this.$emit("handleScanWaitInvoice", row);
      //if(row.disposeStatus==='WCL'){
        this.editScanWaitStatus(row.id, "N");
      //}
    },
    // 表格值格式化
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case "开票类型":
          return this.handleValueToLabel("BillingType", value);
        case "购方名称":
          if (value == null || value == "") {
            return "--";
          }
        case "纳税人识别号":
          if (value == null || value == "") {
            return "--";
          }
        case "地址/电话":
          if (value == null || value == "") {
            return "--";
          }
        case "银行账号":
          if (value == null || value == "") {
            return "--";
          }
        case "电子邮箱":
          if (value == null || value == "") {
            return "--";
          }
        case "手机号码":
          if (value == null || value == "") {
            return "--";
          }
        default:
          return value;
      }
    },
    /* 切换分页条数 */
    handleSizeChange(value) {
      this.queryForm.size = value;
      this.handleQuery();
    },
    /* 翻页 */
    handleCurrentChange(value) {
      this.queryForm.page = value;
      this.handleQuery();
    },
    cleanList() {},
  },
};
</script>

<style lang="scss" scoped>
.content-select {
  text-align: left;
  .select-body {
    padding: 20px 20px 20px 20px;

    .select-input {
      width: 100%;
    }
  }
}

.content-main {
  min-height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content-table {
    width: 100%;
    .el-button {
      padding: 0;
    }
  }
}

.content-main {
  .invoice-wait-table ::v-deep .DisableSelection > .cell {
    display: none !important;
  }
}

.dialog-content {
  text-align: left;
  margin-top: -6px;
  margin-left: 24px;
}

.dialog-body {
  margin: -16px 24px 0 24px;

  .body-top {
    .top-first-row {
      display: flex;
      margin-bottom: 12px;
    }

    .top-second-row {
      display: flex;
      text-align: left;
      margin-bottom: 24px;

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 20em;
      }
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .body-bottom {
    text-align: left;
    border-top: 1px solid #e9e9e9;
    margin: 0 -24px;
    padding: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}

.el-dialog__footer {
  padding: 10px 24px 24px;
  text-align: right;
  box-sizing: border-box;
}
.diffRed {
  color: red;
}
</style>

<style>
.scan-input .el-input__inner {
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  cursor: pointer;
}
</style>
