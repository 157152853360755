<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="content-main">
        <div class="invoice-wrap">
          <div class="invoice-header">
            <span class="invoice-no">
              <label>订单编号：</label>
              <el-input type="tel" v-float="0" v-model="invoiceInfo.dataId" placeholder="请输入订单编号" maxlength="20"></el-input>
            </span>
            <span class="invoice-title"
              >增值税<span class="title-type">{{ invoiceTitle }}</span
              >发票</span
            >
            <span class="invoice-open-button">
              <el-button type="primary" :disabled="billingButtonDisabled" :loading="billingLoading" @click="handleBillingInvoice">开具</el-button>
            </span>
          </div>
          <div class="invoice-nav">
            <div class="invoice-buyer-info">
              <span class="buyer-tag">购买方</span>
              <invoice-open-user-info class="user-info-form" :buyer-info="buyerInfo" :billing-type="invoiceInfo.billingType" @fitBuyerInfo="fitBuyerInfo"></invoice-open-user-info>
            </div>
            <div class="invoice-user-address">
              <invoice-open-user-address :buyerEmail="buyerEmail" @handleChangedRadio="handleChangedRadio" @handleDZPPcheck="handleDZPPcheck"></invoice-open-user-address>
            </div>
          </div>
          <div class="interval"></div>
          <div class="invoice-goodsList">
            <invoice-open-goods-list ref="invoiceGoodsList" :tax-status="hadTax" :goods-list="goodsList" :biz-type="redType ? 'manual' : 'red'" :goods-index="goodsIndex" @handleFocusTd="handleFocusTd" @handleInsertMerchandise="handleInsertMerchandise" @handleDiscount="handleDiscount" @changeIndex="changeIndex" @dataSelect="handleCurrentGoods" @handleShowGoods="handleShowGoods" @nameBlur="handleMerchandiseNameBlur" @reviewInvoice="handleReviewInvoice"></invoice-open-goods-list>
          </div>
          <div class="interval"></div>
          <div class="invoice-aside">
            <div class="invoice-seller-info">
              <span class="seller-tag">销售方</span>
              <div class="seller-info">
                <el-row class="row-first">
                  <label>名&nbsp;&nbsp;称:</label>
                  <el-dropdown @command="fitSellerInfo">
                    <span class="el-dropdown-link"
                      >{{ sellerInfo.sellerName }}
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="item in sellerList" :key="item.id" :command="item">{{ item.name }} </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-row>
                <el-row class="seller-item row-first">
                  <label>纳税人识别号:</label>
                  <span>{{ sellerInfo.sellerTaxNo }}</span>
                </el-row>
                <el-row class="seller-item row-first">
                  <label>地址、电话:</label>
                  <span>{{ sellerInfo.sellerAddressTel }}</span>
                </el-row>
                <el-row class="seller-item">
                  <label>开户行及账号:</label>
                  <span>{{ sellerInfo.sellerBankAccount }}</span>
                </el-row>
              </div>
            </div>
            <div class="invoice-seller-ext">
              <span class="seller-tag">备注</span>
              <textarea v-model="invoiceInfo.remark" maxlength="140" cols="30" rows="10"></textarea>
            </div>
          </div>
          <div class="invoice-foot">
            <div class="foot-item">
              <label>收款人:</label>
              <el-input v-model="invoiceInfo.payee" placeholder="请输入收款人姓名"></el-input>
            </div>
            <div class="foot-item auditor">
              <label>复核人:</label>
              <el-input v-model="invoiceInfo.auditor" placeholder="请输入复核人姓名"></el-input>
            </div>
            <div class="foot-item payer">
              <label>开票人：</label>
              <el-input type="text" v-model="invoiceInfo.payer" placeholder="请输入复核人姓名"></el-input>
            </div>
          </div>
        </div>
      </div>
      <invoice-bill v-if="issueVisible" :show.sync="issueVisible" :invoice="issueData" @billing-invoice="handleBillingManualInvoice"> </invoice-bill>
      <!-- 发票超限额 -->
      <el-dialog append-to-body title="超限额提醒" :visible.sync="limitVisible" width="510px">
        <div class="limit-dialog">
          <div class="data">
            当前设备开具{{ fmtBillingType(limit.billingType) }}限额：<span>{{ limit.limit }}</span>
          </div>
          <div class="message">当前待开发票价税合计超过税控设备开票限额，您可将开票信息进行拆分。</div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="limitVisible = false">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <el-dialog append-to-body :title="billingReturnInfo" :visible.sync="billingReturnInfoVisible" @close="closeBillingReturnInfo" width="410px">
      <invoice-billing-return-info :printInfo="printInfo" :billingInfo="billingInfo" :buttonShow="buttonShow" @closeBillingReturnInfo="closeBillingReturnInfo" @navigateToAlready="navigateToAlready" :show.sync="issueVisible"> </invoice-billing-return-info>
    </el-dialog>
    <!-- 新增商品 -->
    <el-dialog append-to-body title="新增" :visible.sync="editVisible" :show-close="false" :destroy-on-close="true" @close="handleCancelEditDialog">
      <merchandise-edit v-if="editVisible" :merchandise="merchandise" :is-retail="true" :content="taxPreferentialContents" scene="Billing" @edit-finish="handleMerchandiseEditFinish" @handleCancelEditDialog="handleCancelEditDialog"></merchandise-edit>
    </el-dialog>
    <el-dialog append-to-body title="选择商品" :visible.sync="dialogGoodsListVisible" width="804px" :show-close="false">
      <merchandise-list-dialog v-if="dialogGoodsListVisible" @handleCurrentGoods="handleCurrentGoods"></merchandise-list-dialog>
    </el-dialog>
  </div>
</template>

<script>
import InvoiceOpenUserInfo from '../components/InvoiceOpenUserInfo';
import InvoiceOpenUserAddress from '../components/InvoiceOpenUserAddress';
import MerchandiseEdit from '@/views/system/merchandise/MerchandiseEdit';
import InvoiceOpenGoodsList from '@/views/invoice-open/invoice-issue/retail/components/RetailInvoiceOpenGoodsList';
import MerchandiseListDialog from '../components/MerchandiseListDialog';
import InvoiceBill from '@/views/invoice-open/components/invoice-bill';
import { DataUtil } from '@/util/dataUtil';
import InvoiceBillingReturnInfo from '@/views/invoice-open/invoice-issue/components/InvoiceBillingReturnInfo';

import { checkInvoiceLimit } from '@/service/sal/invoice';

import { getCurrentUserDefaultConfig, getEquipmentByOrgId, manualInvoiceIssue } from '@/service/invoice-open';

export default {
  name: 'HotelInvoiceManual',
  components: {
    InvoiceOpenUserInfo,
    InvoiceOpenUserAddress,
    InvoiceOpenGoodsList,
    InvoiceBill,
    InvoiceBillingReturnInfo,
    MerchandiseEdit,
    MerchandiseListDialog
  },
  computed: {
    // 校验商品列表、购买方名称、税号、收款人、复核人、开票人
    billingButtonDisabled() {
      if (this.goodsList.length > 0) {
        const index = this.goodsList.findIndex((i) => i.taxClassName == '' || i.taxClassName == null);
        const amount = this.goodsList.findIndex((i) => i.sumAmount == '' || i.sumAmount == 0 || i.sumAmount == null);
        if (index > -1 || amount > -1) {
          return true;
        }
      } else {
        return true;
      }
      if (this.invoiceInfo.billingType == 'Zzzp') {
        if (this.buyerInfo.buyerName && this.buyerInfo.buyerTaxNo && this.buyerInfo.buyerAddressTel && this.buyerInfo.buyerBankAccount) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.buyerInfo.buyerName && this.invoiceInfo.payee && this.invoiceInfo.payer && this.invoiceInfo.auditor) {
          return false;
        } else {
          return true;
        }
      }
    }
  },
  data() {
    return {
      orgId: '', //组织id
      manual: 'manual',
      invoiceTitle: '',
      billingLoading: false,
      dialogGoodsListVisible: false,
      issueVisible: false,
      limitVisible: false,
      limit: {},
      goodsIndex: 0, //商品标识符
      currentGoodsIndex: 0, //当前选中商品标识符
      buyerInfo: {
        buyerName: '',
        buyerTaxNo: '',
        buyerAddressTel: '',
        buyerBankAccount: ''
      },
      sellerList: this.$store.state.user.organizations,
      sellerInfo: {
        sellerName: '',
        sellerTaxNo: '',
        sellerAddressTel: '',
        sellerBankAccount: ''
      },
      buyerEmail: {
        receiveEmail: '',
        expressPhone: '',
        expressContact: '',
        expressAddress: '',
        billingChannel: ''
      },
      billingTypes: {
        Dzpp: '电普',
        DZZP: '电专',
        Zzpp: '纸普',
        Zzzp: '纸专'
      },
      invoicePrice: {}, //发票金额相关
      invoiceInfo: {
        dataId: '', //发票编号（订单编号）
        billingType: 'Dzpp', //开具类型(电子发票/普通发票)
        payee: '', //收款人
        auditor: '', //复核人
        payer: '', //开票人
        remark: '' //备注
      },
      issue: {},
      issueData: {},
      DZPPCheck: false,
      forceUnlock: false,
      forceUnlockData: {},
      billingReturnInfo: '',
      billingReturnInfoVisible: false,
      printInfo: '',
      billingInfo: '',
      buttonShow: false,
      hadTax: true,
      goodsList: [],
      redType: true,
      editVisible: false,
      merchandise: {
        orgId: this.$store.state.invoiceIssue.CurrentOrganization,
        uniqueCode: undefined,
        merchandiseName: '',
        taxRate: null,
        merchandiseCategory: '',
        isTaxPreferential: '',
        taxPreferentialContent: '',
        taxClassName: '',
        taxClassCode: '',
        taxRateModel: ''
      },
      taxPreferentialContents: []
    };
  },
  created() {
    // this.receivedMsg();
    let userOrgId = this.$store.state.user.currUser.orgId;
    const org = this.sellerList.find((i) => i.id == userOrgId);
    if (!org) {
      this.toast('请设置您的所属组织！', 'warning');
      return;
    }
    this.fitSellerInfo(org);
    this.handleChangedRadio('Dzpp');
  },
  methods: {
    // 收到消息
    receivedMsg() {
      // this.$options.sockets.onmessage = ({ data }) => {
      //   console.log(data);
      //   this.toast(data);
      // };
    },
    // 初始化复制信息
    initCopyData() {
      if (this.$store.state.invoiceIssue.copyInvoice.id) {
        const copyData = DataUtil.clone(this.$store.state.invoiceIssue.copyInvoice);

        // 购方信息
        for (let b in this.buyerInfo) {
          this.buyerInfo[b] = copyData[b];
        }
        // 购方交付信息
        for (let e in this.buyerEmail) {
          this.buyerEmail[e] = copyData[e];
        }
        // 票面相关
        this.invoiceInfo.dataId = copyData['dataId'];
        this.invoiceInfo.remark = copyData['remark'];
        this.handleChangedRadio(copyData['billingType']);
        // 电票邮箱校验 - 复制过来默认通过，修改后重新触发校验
        this.DZPPCheck = this.invoiceInfo.billingType == 'Dzpp' || this.invoiceInfo.billingType == 'DZZP' ? true : false;
        // 商品列表
        this.goodsList = [];
        copyData.items.forEach((val, index) => {
          val['index'] = index;

          if (val.taxPreferentialContent == '免税' || val.taxPreferentialContent == '不征税') {
            val['taxRateModel'] = val.taxPreferentialContent;
          } else {
            val['taxRateModel'] = val.taxRate;
          }

          val['taxClassNameModel'] = `*${val.taxClassName}*${val.taxPreferentialContent}`;
          this.goodsList.push(val);
        });
        this.goodsIndex = copyData.items.length;

        // 焦点表格第一行第2列-第一个商品的规格型号
        this.$refs.invoiceGoodsList.focusSpec();

        // 清空缓存
        this.$store.commit('invoiceIssue/clearCopyInvoice');
      }
    },
    // 选取购方信息
    fitBuyerInfo(item) {
      this.buyerInfo.buyerAddressTel = item.addressPhone;
      this.buyerInfo.buyerName = item.name;
      this.buyerInfo.buyerTaxNo = item.taxCode;
      this.buyerInfo.buyerBankAccount = item.bankAccount;
    },
    // 选取销方信息
    async fitSellerInfo(item) {
      this.orgId = item.id;
      this.sellerInfo.sellerName = item.name;
      this.sellerInfo.sellerTaxNo = item.taxNo;
      this.sellerInfo.sellerAddressTel = item.addressTel;
      this.sellerInfo.sellerBankAccount = item.bankAccount;
      const { success, data } = await getEquipmentByOrgId({ orgId: item.id });
      if (success) {
        this.issue.billingChannel = data.billingChannel;
        this.buyerEmail.billingChannel = data.billingChannel;
        if (this.invoiceInfo.payer == '' || this.invoiceInfo.payer == null) {
          this.invoiceInfo.payee = data.payee;
          this.invoiceInfo.payer = data.payer;
          this.invoiceInfo.auditor = data.auditor;
        }
      }
    },
    // 构建发票参数
    handleBillingInvoice() {
      if ((this.invoiceInfo.billingType == 'Dzpp' || this.invoiceInfo.billingType == 'DZZP') && !this.buyerEmail.receiveEmail) {
        this.toast('电子发票，电票交付不可为空！', 'error');
        return;
      } else if ((this.invoiceInfo.billingType == 'Dzpp' || this.invoiceInfo.billingType == 'DZZP') && !this.DZPPCheck) {
        this.toast('电子发票，邮件地址错误！', 'error');
        return;
      }

      this.$refs.invoiceGoodsList.computedInvoicePrice();
      // 待开发票
      this.issue = {
        orgId: this.orgId,
        billingType: this.invoiceInfo.billingType, //开具类型
        billingFlag: 'Lp', //开具标识
        items: this.goodsList, //发票明细
        ...this.buyerInfo, //购方信息
        ...this.buyerEmail, //邮寄相关
        ...this.sellerInfo, //销方信息,
        ...this.invoiceInfo, //票面信息
        ...this.invoicePrice //发票金额
      };
      // console.log(this.issue)
      if (this.issue.sumAmount <= 0) {
        this.toast('含税金额不可小于等于0，请重新输入', 'error');
        return;
      }
      // 免税不征税-重置税率为0 - 校验发票明细行
      this.issue.items.forEach((i) => {
        if (!i.price && i.quantity) {
          this.toast('明细中商品单价为空时，商品数量也必须为空', 'warning');
          return;
        }
        i.taxAmount = i.taxAmount == '***' ? 0 : Number(Number(i.taxAmount).toFixed(2));
        i.taxRate = i.taxRate == '免税' || i.taxRate == '不征税' ? 0.0 : Number(Number(i.taxRate).toFixed(2));
        i.amount = Number(Number(i.amount).toFixed(2));
        i.sumAmount = Number(Number(i.sumAmount).toFixed(2));
      });
      this.handleCheckLimit();
    },
    // 校验发票限额
    async handleCheckLimit() {
      const { success, data, message } = await checkInvoiceLimit(this.issue);

      if (!success) {
        this.toast(message, 'error');
        return;
      }
      if (data.success) {
        this.handleToastIssueResult();
      } else {
        this.limitVisible = true;
        this.limit = data;
      }
    },
    // 展开发票结果展示
    handleToastIssueResult() {
      // 非电子发票获取库存
      this.issueVisible = true;
      this.issueData = this.issue;
    },
    // 发票开具
    async handleBillingManualInvoice(printable, back) {
      //判断连开带打的标识
      if (printable == 'Y') {
        this.issue.printable = true;
      } else {
        this.issue.printable = false;
      }
      const { success, data, message } = await manualInvoiceIssue(this.issue);
      if (success) {
        if (data.isSync) {
          this.billingInvoiceId = data.billingInvoiceId;

          if (data.isPrint) {
            if (data.billingState) {
              // console.log('data==>', data)
              this.billingReturnInfo = '发票开具成功！';
              this.billingReturnInfoVisible = true;
              if (data.printState) {
                this.printInfo = '正在驱动打印机执行打印操作，请稍后......';
              } else {
                this.printInfo = '驱动打印机失败：根据接口返回原因进行展示';
              }
            } else {
              this.printInfo = '错误原因：根据接口返回原因进行展示！';
              this.billingInfo = '你可以在"开票记录"中重试。';
              this.billingReturnInfo = '发票开具失败！';
              this.buttonShow = true;
              this.billingReturnInfoVisible = true;
            }
          }
        }
      } else {
        this.toast(`${message}请在开票记录中查看结果。`, 'error');
      }
      if (back) back(success);
    },
    // /* 发票价税补全 */
    // handleReviewInvoice (invoicePrice) {
    //   this.invoicePrice = invoicePrice
    // },
    /* 切换发票类型 */
    handleChangedRadio(k) {
      this.invoiceInfo.billingType = k;
      this.invoiceTitle = this.billingTypes[k];
    },
    // /* 展开商品选择弹窗 */
    // handleShowGoods (index) {
    //   this.dialogGoodsListVisible = true
    //   this.currentGoodsIndex = index
    // },
    // /* 商品标识符 */
    // changeIndex (value) {
    //   this.goodsIndex += value
    // },
    // /* 商品选中 */
    // handleCurrentGoods (row) {
    //   //let goods = this.goodsList[this.goodsList.findIndex(i => i.index == this.currentGoodsIndex)];
    //   const goods = this.goodsList[row.index]
    //   if (row.specification) {
    //     goods.specification = row.specification
    //   }
    //   if (row.unit) {
    //     goods.unit = row.unit
    //   }
    //   // 免税-不征税明细
    //   if (row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税') {
    //     goods.taxRateModel = row.taxPreferentialContent
    //     goods.taxRate = 0
    //   } else {
    //     goods.taxRate = Number(row.taxRate)
    //     goods.taxRateModel = Number(row.taxRate)
    //   }
    //   goods.price = row.price == 0 ? null : row.price
    //   goods.uniqueCode = row.uniqueCode
    //   goods.taxClassCode = row.taxClassCode
    //   goods.merchandiseName = row.taxClassName
    //   goods.taxClassName = row.taxClassName
    //   goods.taxVersionNo = row.taxVersionNo
    //   goods.isTaxPreferential = row.isTaxPreferential
    //   goods.taxPreferentialContent = row.taxPreferentialContent
    //   this.$refs.invoiceGoodsList.handleKeyupGoodsPrice(goods)
    //   // 焦点移动
    //   // this.$refs.invoiceGoodsList.focusNext()
    //   this.dialogGoodsListVisible = false
    // },
    /* 加载弹窗 */
    handleLoading() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      return loading;
    },
    // 电子普票校验
    handleDZPPcheck(status) {
      this.DZPPCheck = status;
    },
    fmtBillingType(val) {
      return this.handleValueToLabel('BillingType', val);
    },
    navigateToAlready() {
      this.$emit('navigateToAlready');
    },
    closeBillingReturnInfo() {
      this.issueVisible = false;
      this.billingReturnInfoVisible = false;
    },
    // 表格项被焦点
    handleFocusTd(val) {
      this.currentGoodsIndex = val.index;
      // 商品明细已经填写了 "名称 & 金额 & 税率"
      if (val.merchandiseName && val.amount && val.taxRate != null) {
        this.hadTdFocus = val.focus;
        this.discountStatus = val.itemProperty > 0 ? true : false;
      } else {
        this.hadTdFocus = false;
      }
    },
    // 新增商品信息弹窗
    handleInsertMerchandise(row) {
      this.merchandise.index = row.index;
      this.merchandise.merchandiseName = row.merchandiseName;
      this.editVisible = true;
    },
    // 显示折扣相关弹窗
    handleDiscount() {
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      this.discountForm = { ...this.discountForm, ...goods };
      this.deleteGoods = goods.itemProperty == '2';
      this.discountStatus ? (this.deletedDiscountVisible = true) : (this.insertDiscountVisible = true);
    },
    // 商品标识符
    changeIndex(value) {
      this.goodsIndex += value;
    },
    // 商品选中
    handleCurrentGoods(row) {
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      if (!goods) return;

      if (row.specification) {
        goods.specification = row.specification;
      }
      if (row.unit) {
        goods.unit = row.unit;
      }
      // 免税-不征税明细
      if (row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税') {
        goods['taxRateModel'] = row.taxPreferentialContent;
        goods['taxRate'] = 0;
      } else {
        goods['taxRate'] = Number(row.taxRate);
        goods['taxRateModel'] = Number(row.taxRate);
      }
      goods.price = row.price == 0 ? null : row.price;
      goods.uniqueCode = row.uniqueCode;
      goods.taxClassCode = row.taxClassCode;

      // 显示值
      if (!row.merchandiseName && row.untaxMerchandiseName) {
        row.merchandiseName = row.untaxMerchandiseName;
      }
      goods.merchandiseName = /^\*/.test(row.merchandiseName) ? row.merchandiseName : `*${row.taxClassName}*${row.merchandiseName}`;
      goods.notFirst = true;
      goods.taxClassName = row.taxClassName;
      goods.taxVersionNo = row.taxVersionNo;
      goods.isTaxPreferential = row.isTaxPreferential;
      goods.taxPreferentialContent = row.taxPreferentialContent;
      goods.priceVariable = row.priceVariable;
      goods.visible = false;

      this.$refs.invoiceGoodsList.handleKeyupGoodsPrice(goods);
      this.dialogGoodsListVisible = false;
      let inputDom = document.getElementById('input-' + row.index);
      inputDom ? inputDom.blur() : null;

      // 焦点移动
      setTimeout(() => this.$refs.invoiceGoodsList.focusSpec(goods.index), 0);
    },
    // 展开商品选择弹窗
    handleShowGoods(goods) {
      this.dialogGoodsListVisible = true;
      this.currentGoodsIndex = goods.index;
    },
    // 输入货物清单通知
    handleMerchandiseNameBlur(row, merchandises) {
      //  失去焦点行下标
      this.currentGoodsIndex = row.index;
      if (!row.taxClassCode) {
        this.handleInsertMerchandise(DataUtil.clone(row));
        return;
      }
      this.handleCurrentGoods(row);
    },
    // 发票价税补全
    handleReviewInvoice(invoicePrice) {
      this.invoicePrice = invoicePrice;
    },
    // 新增商品信息弹窗 关闭的回调
    handleCancelEditDialog() {
      this.editVisible = false;
      this.merchandise = {
        orgId: null,
        uniqueCode: undefined,
        merchandiseName: '',
        taxRate: null,
        merchandiseCategory: '',
        isTaxPreferential: '',
        taxPreferentialContent: '',
        taxClassName: '',
        taxClassCode: '',
        taxRateModel: ''
      };
      this.$refs.invoiceGoodsList.focusInput(this.currentGoodsIndex);
    },
    // 新增商品完成
    handleMerchandiseEditFinish(row) {
      this.editVisible = false;
      this.handleCurrentGoods(row);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-wrap {
  background-color: #fff;
}

.content-select {
  box-shadow: none;

  .select-body {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.content-main {
  margin-top: 0;
  padding: 24px;
  border-color: #9d5224;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
  }

  ::v-deep .el-radio__inner {
    border: 1px solid #a7643b;
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #409eff;
  }

  ::v-deep .el-input__inner:hover {
    border: none;
    border-bottom: 1px solid #1890ff;
    border-radius: 0;
  }

  .interval {
    height: 16px;
    border-left: 1px solid;
    border-right: 1px solid;
  }

  .invoice-wrap {
    max-width: 1200px;
    border: 1px solid #979797;
    padding: 30px 20px 20px 20px;
    background-color: #fffffd;
    color: #9d5224;

    .invoice-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      line-height: 1.8;

      .invoice-no,
      invoice-open-button {
        display: inline-block;
      }

      .invoice-title {
        margin-left: -200px;
        font-size: 32px;
        border-bottom: 4px double;

        .title-type {
          color: #1890ff;
        }
      }
    }

    .invoice-nav {
      margin-top: 16px;
      display: flex;
      flex-direction: row;

      .invoice-buyer-info {
        display: flex;
        flex-direction: row;
        border: 1px solid;
        width: 590px;
        min-width: 480px;
        height: 210px;

        .buyer-tag {
          border-right: 1px solid;
          display: flex;
          text-align: center;
          align-items: center;
          width: 30px;
          padding: 0 10px;
          height: 100%;
          line-height: 1.8;
        }
      }

      .invoice-user-address {
        flex: auto;
        height: 210px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-right: 1px solid;
      }
    }

    .invoice-aside {
      display: flex;
      flex-direction: row;

      .invoice-seller-info {
        display: flex;
        flex-direction: row;
        border: 1px solid;
        width: 600px;
        min-width: 600px;
        height: 210px;
      }

      .invoice-seller-ext {
        flex: auto;
        display: flex;
        flex-direction: row;
        height: 210px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-right: 1px solid;

        textarea {
          color: #333333;
          margin: 24px;
          width: 100%;
          border: none;
          outline: none;
          resize: none;
        }
      }

      .seller-tag {
        border-right: 1px solid;
        display: flex;
        text-align: center;
        align-items: center;
        width: 26px;
        padding: 0 10px;
        height: 100%;
        line-height: 1.8;
      }

      .seller-info {
        display: block;
        padding: 24px;

        .seller-item {
          display: flex;
          flex-direction: row;
        }

        .row-first {
          margin-bottom: 24px;
        }
      }

      label {
        display: inline-block;
        width: 100px;
      }
    }

    .invoice-foot {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .foot-item {
        line-height: 40px;
        height: 40px;
      }

      .auditor,
      .payer {
        margin-left: 40px;
      }

      label {
        display: inline-block;
        width: 60px;
      }
    }
  }
}

label + span {
  color: #333333;
}

.limit-dialog {
  margin-top: -20px;
  padding-left: 20px;
  text-align: left;
  font-size: 14px;

  .data {
    padding-bottom: 25px;
    border-bottom: 1px solid #e9e9e9;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }
  }

  .message {
    padding: 24px 0;
    color: #f5222d;
  }
}

.setting {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 0px !important;
  }

  ::v-deep .el-tabs__item {
    margin-top: 0px !important;
  }
}
</style>
