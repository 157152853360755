<template>
  <div>
    <!-- 核对发票 -->
    <el-dialog append-to-body title="打印单张发票" :visible.sync="singleVisible" width="600px">
      <el-form label-width="100px" size="mini" style="text-align: initial; padding: 0px 20px;">
        <el-row>
          <el-col :span="24">
            <el-form-item label="发票类型：">{{invoiceType}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票代码：">{{ invoice.invoiceCode }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票号码：">{{ invoice.invoiceNo }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="购方名称：">{{invoice.buyerName}}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="购方税号：">{{invoice.buyerTaxNo}}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="地址电话：">{{invoice.buyerAddressTel}}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="银行账号：">{{invoice.buyerBankAccount}}</el-form-item>
          </el-col>
          <el-col :span="24" style="color: #F5222F; font-size: 14px; text-indent: 1em; padding-bottom: 10px;">
            请认真核对即将打印的纸质发票的类型、代码、号码与当前展示的信息，确认一致，请执行打印；如不一致，请予以更换！
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" :disabled="['QD-RPA'].indexOf(invoice.billingChannel) > -1" @click="handleConfirmPrint(1)">打印清单
        </el-button>
        <el-button type="primary" @click="handleConfirmPrint(0)">打印发票</el-button>
      </div>
    </el-dialog>

    <!-- 打印设置 -->
    <el-dialog append-to-body title="发票打印设置" :visible.sync="setupVisible" width="600px">
      <el-form ref="setupForm" :model="setupForm" label-width="100px" size="mini"
               style="text-align: initial; padding: 0px 20px;">
        <el-form-item label="打印方式" prop="way">
          <el-select v-model="setupForm.way">
            <el-option v-for="item in wayOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="向下调整" prop="sbj" v-if="setupForm.way=='standard'">
          <el-input v-model="setupForm.sbj">
            <template slot="append">毫米</template>
          </el-input>
        </el-form-item>
        <el-form-item label="向右调整" prop="zbj" v-if="setupForm.way=='standard'">
          <el-input v-model="setupForm.zbj">
            <template slot="append">毫米</template>
          </el-input>
        </el-form-item>
        <el-form-item label="弹窗设置" prop="tsk">
          <el-switch v-model="setupForm.tsk" inactive-text="不再弹窗" active-text="每次弹窗"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="default"
                   @click="handleCancelSetup">取消
        </el-button>
        <el-button type="primary"
                   v-show="action=='setup'"
                   @click="handleConfirmSetup">确认
        </el-button>
        <el-button type="primary"
                   :loading="printing"
                   :disabled="printing"
                   v-show="action=='print'"
                   @click="handleSetupPrint">打印
        </el-button>
      </div>
    </el-dialog>

    <!-- 批量打印 -->
    <el-dialog append-to-body title="打印多张发票" :visible.sync="batchVisible" width="600px">
      <el-form label-width="100px" size="mini" style="text-align: initial; padding: 0px 20px;">
        <el-col :span="24">
          <el-form-item label="发票类型：">{{invoiceType}}</el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发票代码：">{{ invoiceCode }}</el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发票张数："><span style="font-weight: bold;">{{invoices.length}}</span></el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="起始号码：">{{ invoiceNoQ }}</el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="终止号码：">{{ invoiceNoZ }}</el-form-item>
        </el-col>
      </el-form>
      <div slot="footer">
        <el-button type="primary"
                   :loading="printing"
                   :disabled="printing"
                   @click="handleConfirmPrint(1)">打印清单
        </el-button>
        <el-button type="primary"
                   :loading="printing"
                   :disabled="printing"
                   @click="handleConfirmPrint(0)">打印发票
        </el-button>
      </div>
    </el-dialog>

    <!-- 通用打印 -->
    <iframe ref="iframe"
            src="support/invoice-print/index.html"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            width="0px"
            height="0px"></iframe>
  </div>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib'
import cookieUtil from '@/util/cookieUtil'
import print from 'print-js'
import { printInovice, querySingleInvoice } from '@/service/sal/invoice'

export default {
  name: 'InvoicePrint',
  props: {
    invoices: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data () {
    return {
      // 设置行为 setup:设置 print:打印
      action: 'setup',
      // 打印标识 0:打印发票 1:打印清单
      printFlag: '0',
      // 打印设置弹窗
      setupVisible: false,
      setupForm: {
        'zbj': 0,
        'sbj': 0,
        'way': 'ordinary',
        'tsk': false
      },
      // 单张打印弹窗
      singleVisible: false,
      // 批量打印弹窗
      batchVisible: false,
      printing: false,
      imageContents: '',
      wayOptions: [
        {
          value: 'standard',
          label: '通用打印'
        },
        {
          value: 'ordinary',
          label: '助手打印'
        }
      ]
    }
  },
  computed: {
    // 首张发票
    invoice () {
      if (this.invoices.length < 1) return {}
      return this.invoices[0]
    },
    // 发票类型
    invoiceType () {
      if (this.invoices.length < 1) return '--'
      return Array.from(new Set(this.invoices.map(i => i.billingType))).map(v => this.handleValueToLabel('BillingType', v)).join('、')
    },
    // 发票代码
    invoiceCode () {
      if (this.invoices.length < 1) return '--'
      return this.invoices[0].invoiceCode
    },
    // 发票号码/发票号码起
    invoiceNoQ () {
      if (this.invoices.length < 1) return '--'
      return this.sortedInvoices[0].invoiceNo
    },
    // 发票号码止
    invoiceNoZ () {
      if (this.invoices.length < 1) return '--'
      return this.sortedInvoices[this.invoices.length - 1].invoiceNo
    },
    sortedInvoices () {
      if (this.invoices.length < 1) return []
      return this.invoices.sort((a, b) => Number(a.invoiceNo) - Number(b.invoiceNo))
    }
  },
  methods: {
    // 打开设置
    openSetup (flag) {
      this.printFlag = flag == 'Invoice' ? 0 : 1
      this.action = 'setup'
      this.setupForm = this.getSetting()
      this.setupVisible = true
    },
    // 打开打印
    openPrint () {
      if (this.invoices.length < 1) {
        this.toast('请选择需要打印的发票信息！', 'warning')
        return
      } else if (this.invoices.length == 1) {
        let typeArr1 = this.invoices.filter(i => ['BW-XML-SKP', 'HX-XML-JSP'].indexOf(i.billingChannel) > -1)
        // 单类校验
        if (new Set(typeArr1).size >= 1) {
          this.toast('包含百旺/航信-XML开票数据，请使用线下打印！', 'warning')
          return
        }
        this.singleVisible = true
      } else {
        if (this.setupForm.way == 'webPrint') {
          this.batchVisible = true
          return;
        }

        let typeArr = this.invoices.map(i => i.billingType)
        // 纸票校验
        if (typeArr.findIndex(i => /D/.test(i)) > -1) {
          this.toast('所选发票包含非纸质发票！', 'warning')
          return
        }
        let typeArr1 = this.invoices.filter(i => ['BW-XML-SKP', 'HX-XML-JSP'].indexOf(i.billingChannel) > -1)
        // 单类校验
        if (new Set(typeArr1).size >= 1) {
          this.toast('包含百旺/航信-XML开票数据，请使用线下打印！', 'warning')
          return
        }

        // 单类校验
        if (new Set(typeArr).size > 1) {
          this.toast('所选发票包含多发票类型！', 'warning')
          return
        }

        // 代码校验
        if (new Set(this.invoices.map(i => i.invoiceCode)).size > 1) {
          this.toast('所选发票包含多发票代码！', 'warning')
          return
        }

        // 连号校验
        let isContinuous = true
        let invoiceNoArr = this.sortedInvoices.map(s => s.invoiceNo)
        for (let i = 0; i < invoiceNoArr.length - 1; i++) {
          if (invoiceNoArr[i] - 1 != invoiceNoArr[i - 1]) {
            isContinuous = false
            break
          }
        }
        if (isContinuous) {
          this.batchVisible = true
        } else {
          this.$confirm('所选发票号码不是连续的，是否继续执行打印？', '警告！！！', {
            confirmButton: '继续',
            cancelButtonText: '取消'
          }).then(() => {
            this.batchVisible = true
          })
        }
      }
    },
    // 确认打印
    async handleConfirmPrint (flag) {
      this.printFlag = flag
      let setup = this.getSetting()
      if (setup.tsk) {
        this.action = `print`
        this.setupForm = setup
        // 关闭上层
        if (this.invoices.length > 1) {
          this.batchVisible = false
        } else {
          this.singleVisible = false
        }
        this.setupVisible = true
      } else {
        await this.requestPrint(setup)
      }
    },
    // 设置打印
    async handleSetupPrint () {
      let setup = this.updSetting()
      this.requestPrint(setup)
    },
    // 确认设置
    handleConfirmSetup () {
      if (!this.validateSetup()) return
      this.updSetting()
      this.setupVisible = false
    },
    // 取消设置
    handleCancelSetup () {
      if (this.singleVisible) this.singleVisible = false
      if (this.batchVisible) this.batchVisible = false
      this.setupVisible = false
    },
    // 请求打印
    async requestPrint (setup) {
      const flag = this.printFlag

      let ids = []
      if (flag == 1) {
        ids = this.invoices.filter(i => i.independentDetails == 'Y').map(i => i.id)
        if (ids.length < 1) {
          this.toast('所选发票均不包含清单！', 'warning')
          return
        }
      } else {
        ids = this.invoices.map(i => i.id)
      }

      let printArg = {
        'invoiceIds': ids,
        'printFlag': flag,
        'dialogFlag': 1,
        'useStandard': setup.way == 'standard'
      }

      // 请求服务打印
      this.printing = true
      const { success, data } = await printInovice(printArg)
      this.printing = false
      if (success) {
        // 判断标准打印
        if (printArg.useStandard) {
          // 当标准打印时内容只有一个节点，且为单一发票类型
          let item = data[0]
          const cont = item.printContent
          let cw = this.$refs.iframe.contentWindow
          switch (item.billingType) {
            case 'Zzzp':
              flag == 0 ? cw.ZzszFpdy(cont, 1, setup.zbj, setup.sbj) : cw.Zzszqddy(cont, 1, setup.zbj, setup.sbj)
              break
            case 'Zzpp':
            case 'SGZP':
              flag == 0 ? cw.ZzspFpdy(cont, 1, setup.zbj, setup.sbj) : cw.Zzspqddy(cont, 1, setup.zbj, setup.sbj)
              break
            default:
              this.toast('尚未支持的发票类型！', 'warning')
              return
          }
        } else {
          // 非标准打印时内容是有多个节点，且为单一开票方式
          data.forEach(invoice => {
            const cont = invoice.printContent
            for (let i = 0; i < data.length; i++) {
              let item = data[i]
              if (item.success) {
                switch (invoice.billingChannel) {
                  case 'NN-JG-JSP':
                    this.printUseNN(cont, flag)
                    break
                  default:
                    break
                }
              } else {
                this.toast(`第【${i + 1}】张发票打印失败，提示：${item.message}`, 'error')
              }
            }
          })
        }
        if (this.setupVisible) this.setupVisible = false
        if (this.singleVisible) this.singleVisible = false
        if (this.batchVisible) this.batchVisible = false
        this.toast('驱动成功，正在打印...', 'success')
      }
    },
    // 校验设置
    validateSetup () {
      if (this.setupForm.way == 'standard') {
        if (this.setupForm.sbj == undefined) {
          this.setupForm.sbj = 0
        }
        if (this.setupForm.zbj == undefined) {
          this.setupForm.zbj = 0
        }
        if (!RegexLib.Integer.test(this.setupForm.sbj)) {
          this.toast('向下调整 只能输入整数！', 'error')
          return false
        }
        if (!RegexLib.Integer.test(this.setupForm.zbj)) {
          this.toast('向右调整 只能输入整数！', 'error')
          return false
        }
      } else {
        this.setupForm.sbj = 0
        this.setupForm.zbj = 0
      }
      return true
    },
    //获取设置
    getSetting () {
      const key = this.generateSettingKey(this.printFlag)
      let val = cookieUtil.getValue(key)

      // zbj:左边距 sbj:上边距 way:打印方式 tsk:弹提示框(true=每次弹窗 false=不再弹出)
      if (val) {
        const props = val.split('#')
        return {
          'sbj': props[0],
          'zbj': props[1],
          'way': props[2],
          'tsk': props[3] == 'true'
        }
      }
      return {
        'zbj': 0,
        'sbj': 0,
        'way': 'ordinary',
        'tsk': false
      }
    },
    //更新设置
    updSetting () {
      let flag = this.printFlag
      const key = this.generateSettingKey(flag)
      let setup = this.setupForm
      const val = `${setup.sbj}#${setup.zbj}#${setup.way}#${setup.tsk}`
      cookieUtil.setValue(key, val)
      return setup
    },
    // 存储键名
    generateSettingKey (flag) {
      return `${this.$store.state.user.currUser.id}: coordinate : ${flag == 0 ? 'Invoice' : 'Details'}`
    },
    // 诺诺打印 cont：密文数据 flag：打印类型 0:主票 1:清单
    printUseNN (cont, flag) {
      let url = `webprint:${flag},${cont}`
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 格式化开票方式
    fmtBillingType (val) {
      return this.handleValueToLabel('BillingType', val)
    }
  }
}
</script>

<style scoped lang="scss">
  .el-form-item {
    margin-bottom: 22px;
  }

  ::v-deep .el-form-item__label, ::v-deep .el-form-item__content {
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-input__inner {
    height: 40px;
    line-height: 40px;
  }

  ::v-deep .el-switch {
    vertical-align: inherit;
  }
</style>
