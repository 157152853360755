<!--电子税务局认证 弹窗-->
<template>
  <el-dialog
    append-to-body
    custom-class="taxBureauAuthDialog"
    :visible.sync="visible"
    width="480px"
    title="登录认证"
    :close-on-click-modal="false"
    :before-close="dialogClose"
    @open="dialogOpen"
  >
    <el-tabs v-model="activeName" @tab-click="handleTabsChange">
      <el-tab-pane label="短信认证" name="message">
        <el-form
          class="form-box"
          ref="selectFrom"
          :inline="true"
          label-width="160px"
          :model="formData">
          <!-- <el-form-item class="cus-form-item" label="社会信用代码/识别号" for="unifiedSocialCreditCode">
            <el-input
              v-model="formData.unifiedSocialCreditCode"
              placeholder="请输入社会信用代码/识别号"
              clearable
            ></el-input>
          </el-form-item> -->
          <el-form-item class="cus-form-item" label="企业名称" prop="taxName">
            <!-- <el-select v-model="formData.taxName" @change="taxNameChange">
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id"></el-option>
            </el-select> -->
            <el-input v-model="formData.taxName"></el-input>
          </el-form-item>
          <el-form-item class="cus-form-item" label="社会信用代码" prop="unifiedSocialCreditCode">
            <el-input v-model="formData.unifiedSocialCreditCode"></el-input>
          </el-form-item>
          <el-form-item class="cus-form-item" label="电局手机号" prop="elecAccount">
            <el-input v-model="formData.elecAccount" :disabled="true"></el-input>
            <!-- <el-select v-model="formData.elecAccount" @change="elecAccountChange">
              <el-option
                v-for="item in accountList"
                :key="item.elecAccount"
                :label="item.elecAccount"
                :value="item.elecAccount"></el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item class="cus-form-item" label="手机验证码" prop="veryCode">
            <el-input
              v-model="formData.veryCode"
              placeholder="请输入手机验证码"
              clearable>
              <el-button :disabled="time>0 || loginLoading" class="append-btn" type="primary" slot="append"
                         @click="getCode">
                获取验证码
                <span v-show="time>0">({{ time }})</span>
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item class="submit-btn">
            <el-button type="primary" @click="onSubmit" :disabled="loginLoading">认证</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="扫码认证" name="QRCode">
        <div class="form-box" style="text-align: center;">
          <div class="QR-code-box">
            <el-image @click="refreshQRCode" :src="qrUrl" alt="">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
          <div class="tip">二维码有效剩余时间：<span>{{ countdown }}</span>
            <a style="margin-left: 5px;cursor: pointer" @click="refreshQRCode">点击刷新</a>
          </div>
          <el-button type="primary" class="big-btn" @click="getQrResult">我已完成扫脸认证</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import {getQrResult, qrCode, rpaAuthConfirm, rpaAuthLogin} from "@/service/rpa/rpa-auth";
import {getAccountList} from "@/service/equipment";
import { format } from "echarts";

export default {
  props: {
    visible: {
      type: Boolean,
      // default: false
    },
    // orgId:{
    //   type: Number,
    // },
    // taxName: {
    //   type: String,
    //   default: ''
    // }
  },
  data() {
    return {
      activeName: "message",
      accountList: [],
      organizations:[],
      formData: {
        unifiedSocialCreditCode: "", // 统一社会信用代码
        elecAccount: "",
        elecPassword: "",
        veryCode: "",
        orgId:null
      },
      time: 0, // 验证码剩余时间
      codeInterval: null, // 验证码倒计时
      qrUrl: "", //require("@/components/testqrcode.png"),
      rzid: "", //
      interval: null, // 二维码倒计时
      countdown: "05:00",
      qrCodeId: "",
      loginLoading: false,
    }
  },
  created() {
    // debugger
    this.getAccountList()
  //   this.formData.orgId
    // console.log("电子税局的orgId:"+JSON.stringify(this.$store.state.user.currentOrg))
  },
  watch: {
  '$store.state.invoiceIssue.CurrentOrganization': {
        handler(value) {
          if (value !== 0) {
            
            this.formData.orgId = value;
          }
          console.log("当前组织：：：："+value)
        },
        immediate: true
      },

  },
  methods: {
    dialogOpen() {
    },
    // 获取默认账户信息
    async getAccountList() {
        debugger
    console.log("电子税局的orgId:"+JSON.stringify(this.formData))

      this.organizations = this.$store.state.user.organizations.filter(organization => organization.isTaxpayer === 'Y');
      console.log(this.organizations)
      let filteredOrgs=this.organizations
      let orgDe=filteredOrgs.find(f =>{
        return f.id === this.formData.orgId
      })
      this.formData.unifiedSocialCreditCode=orgDe.taxNo
      this.formData.taxName=orgDe.name
      const {success, data} = await getAccountList(this.formData.orgId)
      if (success && Array.isArray(data)) {
        this.accountList = data
        // console.log("数电账户："+JSON.stringify(this.accountList))
        let item = data.find(f => {
          return f.elecAccount===localStorage.getItem("eleAccount")
        })
        this.qrCodeId = item.id
        // this.formData.unifiedSocialCreditCode = item.orgTaxNo
        this.formData.elecAccount = item.elecAccount
        this.formData.elecPassword = item.elecPassword
      }
    },
    // async taxNameChange(value){
    //   let itemOrg = this.organizations.find(f => {
    //     return f.id == value
    //   })
    //   this.formData.unifiedSocialCreditCode=itemOrg.taxNo
    //   this.formData.taxName=itemOrg.name
    //   const {success, data} = await getAccountList(itemOrg.id)
    //   if (success && Array.isArray(data)) {
    //     debugger
    //     this.accountList = data
    //     let item = data.find(f => {
    //       return f.defaulted
    //     })
    //     this.qrCodeId = item.id
    //     // this.formData.unifiedSocialCreditCode = item.orgTaxNo
    //     this.formData.elecAccount = item.elecAccount
    //     this.formData.elecPassword = item.elecPassword
    //   }
    //   else{
    //     this.toast('请先维护数电账户','warning')
    //     return
    //   }
    // },
    // 手机号切换
    // elecAccountChange(value) {
    //   let item = this.accountList.find(f => {
    //     return f.elecAccount == value
    //   })
    //   this.qrCodeId = item.id
    //   // this.formData.unifiedSocialCreditCode = item.orgTaxNo
    //   this.formData.elecAccount = item.elecAccount
    //   this.formData.elecPassword = item.elecPassword
    // },
    // 获取验证码
    getCode() {
      if (this.time == 0) {
        this.loginLoading = true;
        debugger
        let params = {
          id: this.qrCodeId,
          orgId: this.formData.orgId,
          account: this.formData.elecAccount,
          password: this.formData.elecPassword,
          veryCode: this.formData.veryCode
        }
        rpaAuthLogin(params).then((res) => {
          if (res.code === '1') {
            this.toast(res.message, 'success');
            // this.outerVisible = false;
          } else if (res.code === '0' && res.data) {
            // this.phone = res.data.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
            // this.innerVisible = true;
            this.setCodeInterval()
          } else {
            this.toast(res.message, 'error');
          }
          this.loginLoading = false;
          // this.query();
        });
      }
    },
    // 重新获取验证码的倒计时
    setCodeInterval() {
      if (this.codeInterval) clearInterval(this.codeInterval)
      this.time = 60
      this.codeInterval = setInterval(() => {
        this.time = this.time - 1 // 剩余时间
        if (this.time <= 0) {
          clearInterval(this.codeInterval)
        }
      }, 1000)
    },
    // 获取二维码接口
    async getQrCode() {
      if (!this.qrCodeId) {
        await this.getAccountList()
      }
      let usernamePhone=localStorage.getItem("eleAccount")
      // 获取账户列表及密码 默认取 default为true的 账户  税号取当前组织的税号@苏打水的苏
      const {success, data} = await qrCode({id: this.qrCodeId,orgId: this.formData.orgId,elecAccount:usernamePhone})
      if (success) {
        this.qrUrl = data.qrCode;
        this.rzid = data.rzid;
        this.setCountdown(300)
      }
    },
    handleTabsChange(t) {
      this.getAccountList()
      if (t.name == "QRCode") {
        this.getQrCode()
      }
    },
    // 登录
    async onSubmit() {
      let params = {
        id: this.qrCodeId,
        orgId: this.formData.orgId,
        account: this.formData.elecAccount,
        password: this.formData.elecPassword,
        veryCode: this.formData.veryCode
      }
      const { success } = await rpaAuthConfirm(params);
      if (success) {
        // this.innerVisible = false;
        // this.outerVisible = false;
        this.toast('认证完成!', 'success');
        this.activeName="QRCode"
        this.getQrCode()
        
      }
    },
    // 二维码剩余时间倒计时
    setCountdown(t) {
      if (this.interval) clearInterval(this.interval)
      let s = t % 60 + "" // 剩余秒
      if (s.length == 1) s = "0" + s // 剩余秒是个位数拼接十位数的0
      this.countdown = "0" + parseInt("" + t / 60) + ":" + s // 分 秒 拼接
      this.interval = setInterval(() => {
        t = t - 1 // 剩余时间
        let s = t % 60 + "" // 剩余秒
        if (s.length == 1) s = "0" + s // 剩余秒是个位数拼接十位数的0
        this.countdown = "0" + parseInt("" + t / 60) + ":" + s // 分 秒 拼接
        if (t == 0) {
          clearInterval(this.interval)
        }
      }, 1000)
    },
    refreshQRCode() {
      this.getQrCode()
    },
    async getQrResult() {
      const {success} = await getQrResult(this.qrCodeId + '/' + this.rzid);
      if (success) {
        this.toast('认证成功', 'success');
        this.dialogClose()
      } else {
        this.toast('认证失败', 'error');
      }
    },
    dialogClose() {
      this.$emit("update:visible", false)
    }
  }
}
</script>
<style lang="scss">
.taxBureauAuthDialog {
  .el-dialog__body {
    padding: 0;
  }
}
</style>
<style scoped lang="scss">
.taxBureauAuthDialog {
  .form-box {
    position: relative;
    height: 300px;
    padding-top: 10px;
    text-align: left;

    .cus-form-item {
      margin: 10px 0;

      ::v-deep.el-form-item__content {
        width: 270px;

        .el-select, .el-input {
          width: 100%;
          line-height: 30px;
        }
      }

      .el-input-group__append {
        .append-btn {
          padding: 0 10px;
        }
      }
    }

    .submit-btn {
      position: absolute;
      bottom: 20px;
      right: 50px;
      margin-right: 0;
    }

    .QR-code-box {
      width: 160px;
      height: 160px;
      margin: 10px auto;
      cursor: pointer;
    }

    .tip {
      font-size: 14px;
      color: #aaa;
      text-align: center;

      span {
        color: #1890ff;
        font-weight: bold;
      }
    }

    .big-btn {
      margin: 20px;
      width: 300px;
    }
  }
}
</style>
