import { downloadGet } from "./index";

export const downloadStaticFile = (url, type, name) => {
	// if (["xlsx", "xls"].indexOf(type)) {
	//     let link = document.createElement("a");
	//     link.style.display = "none";
	//     link.href = url;
	//     link.setAttribute("download", `${name}.${type}`);
	//     link.click();
	//     return;
	// }
	downloadGet(url).then(
		(res) => {
			let blobType;
			switch (type) {
				case "xlsx":
				case "xls":
					blobType = "application/vnd.ms-excel";
					break;
				case "xml":
				case "txt":
				case "pdf":
				case "tsv":
				default:
					blobType = "application/octet-stream";
					break;
			}
			const blob = new Blob([res.data], {
				type: blobType,
			});
			let url = window.URL.createObjectURL(blob);
			let link = document.createElement("a");
			link.style.display = "none";
			link.href = url;
			link.setAttribute("download", `${name}.${type}`);
			link.click();
			window.URL.revokeObjectURL(url);
		},
		(err) => {
			console.log("文件下载时出错！");
		}
	);
};
