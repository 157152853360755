<template>
  <el-form class="content-wrap"
           ref="buyerInfo"
           :model="buyerInfo" label-width="120px"
           :rules="buyerInfoRules"
           @keyup.down.native="focusAround(true)"
           @keyup.up.native="focusAround(false)">
    <el-row>
      <el-form-item prop="buyerName" label="名 称:">
        <el-autocomplete id="buyerName" v-model="buyerInfo.buyerName"
                         :fetch-suggestions="queryBuyerNameAsync"
                         :trigger-on-focus="false"
                         @select="handleSelect" placeholder="请输入购方姓名">
          <template slot-scope="{ item }">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                <div>{{item.value}}</div>
                <div class="tooltip-tax-no">{{item.taxCode}}</div>
              </div>
              <div class="auto-drop">
                <span>{{item.value}}</span>
                <img v-if="item.star" src="@/assets/icon/star.png">
              </div>
            </el-tooltip>
          </template>
        </el-autocomplete>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item prop="buyerTaxNo" label="纳税人识别号:">
        <el-input id="buyerTaxNo" class="pdt-input" v-model="buyerInfo.buyerTaxNo" placeholder="请输入纳税人识别码"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item prop="buyerAddressTel" label="地址、电话:">
        <el-input id="buyerAddressTel" class="pdt-input" :class="addressClass" v-model="buyerInfo.buyerAddressTel"
                  placeholder="请输入纳税人地址、电话"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item prop="buyerBankAccount" label="开户行及账号:">
        <el-input id="buyerBankAccount" class="pdt-input" :class="bankClass" v-model="buyerInfo.buyerBankAccount"
                  placeholder="请输入开户行及账号"></el-input>
      </el-form-item>
    </el-row>
  </el-form>
</template>

<script>
import { getBuyerNames } from '@/service/invoice-open'
import { getTntConfig } from '@/service/system/config'

export default {
  name: 'InvoiceOpenUserInfo',
  props: ['buyerInfo', 'billingType'],
  data () {
    return {
      buyerNames: [],
      addressClass: '',
      bankClass: '',
      buyerInfoRules: {},
      rules: [],
      focusList: ['buyerName', 'buyerTaxNo', 'buyerAddressTel', 'buyerBankAccount']
    }
  },
  created () {
    getTntConfig('MANUAL_SEARCH_BUYER').then(({ success, data }) => {
      if (success) {
        if (data != '') {
          this.rules = JSON.parse(data)
        }
      }
    })
  },
  watch: {
    buyerInfo: {
      handler () {
        this.bankClass = (this.buyerInfo.buyerBankAccount && this.buyerInfo.buyerBankAccount.length > 24) ? 'long_class' : null
        this.addressClass = (this.buyerInfo.buyerAddressTel && this.buyerInfo.buyerAddressTel.length > 24) ? 'long_class' : null
      },
      deep: true
    },
    billingType: {
      handler () {
        if (this.billingType == 'Zzzp') {
          this.buyerInfoRules = {
            buyerName: [{ required: true, message: '请输入购方姓名!', trigger: 'blur' }],
            buyerTaxNo: [{ required: true, message: '请输入纳税人识别码!', trigger: 'blur' }],
            buyerAddressTel: [{ required: true, message: '请输入纳税人地址电话!', trigger: 'blur' }],
            buyerBankAccount: [{ required: true, message: '请输入开户行及账号!', trigger: 'blur' }]
          }
        } else {
          this.buyerInfoRules = {
            buyerName: [{ required: true, message: '请输入购方姓名!', trigger: 'blur' }],
          }
        }
        this.$refs.buyerInfo.clearValidate()
      }
    }
  },
  methods: {
    // 远程搜索 （模糊搜索）
    queryBuyerNameAsync (value, fn) {
      getBuyerNames({ keyword: value }).then(res => {
        if (res && res.success) {
          this.buyerNames = res.data.map(i => i.value = i.name)
        }
        fn(res.data)
      })
    },
    // 键盘事件 上/下
    focusAround (type) {
      let domId = document.activeElement.id
      let index = this.focusList.findIndex(i => i === domId)
      if (type) {
        if (index < this.focusList.length - 1) {
          index++
          domId = this.focusList[index]
        }
      } else {
        if (index > 0) {
          index--
          domId = this.focusList[index]
        }
      }
      document.getElementById(domId).focus()
    },
    // 选中options
    handleSelect (item) {
      this.$emit('fitBuyerInfo', item)
      this.$refs.buyerInfo.validate()
    }
  }
}
</script>

<style scoped lang="scss">
  .content-wrap {
    height: auto;
    padding-left: 20px;
    text-align: left;
  }

  ::v-deep .el-row {
    margin: 16px 0;
  }

  ::v-deep .el-form-item__label {
    color: #9D5224;
    text-align: left;
  }

  ::v-deep .el-input__inner {
    width: 360px;
    padding: 0;
  }

  ::v-deep .el-form-item__error {
    top: -12px;
    font-size: 12px;
  }

  .auto-drop {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .tooltip-tax-no {
    padding-top: 6px;
    text-align: right;
  }

  .long_class {
    font-size: 12px;
  }
</style>
