import {del, get, post, put} from '../../index';

/**
 * 分页
 * @param data
 * @returns {Promise<unknown>}
 */
export const labelPage = (data) => {
    return post('/system/label/page', data).then((res) => res);
};

/**
 * 添加
 * @param data
 * @returns {Promise<unknown>}
 */
export const labelAdd = (data) => {
    return post('/system/label/add', data).then((res) => res);
};

/**
 * 保存客户标签
 * @param data
 * @returns {Promise<unknown>}
 */
export const saveCustomerLabel = (data) => {
    return post('/system/label/saveCustomerLabel', data).then((res) => res);
};

/**
 * 修改
 * @param data
 * @returns {Promise<unknown>}
 */
export const labelUpdate = (data) => {
    return put('/system/label/update', data).then((res) => res);
};

/**
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const labelDelete = (data) => {
    return del('/system/label/delete/' + data).then((res) => res);
};

/**
 * 批量删除
 * @param data
 * @returns {Promise<unknown>}
 */
export const deleteBatch = (data) => {
    return del('/system/label/deleteBatch', data).then((res) => res);
};

/**
 * 获取单条
 * @param data
 * @returns {Promise<unknown>}
 */
export const labelGet = (data) => {
    return get('/system/label/get/' + data).then((res) => res);
};
/**
 * 获取客户标签
 * @param data
 * @returns {Promise<unknown>}
 */
export const getCustomerLabels = (data) => {
    return get('/system/label/getCustomerLabels/' + data).then((res) => res);
};

/**
 * 获取所有客户标签
 * @returns {Promise<unknown>}
 */
export const getAllLabels = () => {
    return get('/system/label/getAllLabels').then((res) => res);
};

/**
 * 生成编码
 * @returns {Promise<unknown>}
 */
export const genCode = () => {
    return get('/system/label/genCode').then((res) => res);
};






